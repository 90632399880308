<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Insert Token</DialogTitle>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">Select a poll result token to send back to the user.</p>
                            </div>
                            <div class="mt-8">
                                <form-field>
                                    <form-select label="Field" class="form-select" id="name" name="name" v-model="tag_value.name">
                                        <option value="">- Select Field -</option>
                                        <option value="name1">1st Place Name</option>
                                        <option value="votes1">1st Place Votes</option>
                                        <option value="percent1">1st Place Percent</option>
                                        <option value="name2">2nd Place Name</option>
                                        <option value="votes2">2nd Place Votes</option>
                                        <option value="percent2">2nd Place Percent</option>
                                        <option value="name3">3rd Place Name</option>
                                        <option value="votes3">3rd Place Votes</option>
                                        <option value="percent3">3rd Place Percent</option>
                                        <option value="name4">4th Place Name</option>
                                        <option value="votes4">4th Place Votes</option>
                                        <option value="percent4">4th Place Percent</option>
                                        <option value="name5">5th Place Name</option>
                                        <option value="votes5">5th Place Votes</option>
                                        <option value="percent5">5th Place Percent</option>
                                        <option value="name6">6th Place Name</option>
                                        <option value="votes6">6th Place Votes</option>
                                        <option value="percent6">6th Place Percent</option>
                                        <option value="name7">7th Place Name</option>
                                        <option value="votes7">7th Place Votes</option>
                                        <option value="percent7">7th Place Percent</option>
                                        <option value="name8">8th Place Name</option>
                                        <option value="votes8">8th Place Votes</option>
                                        <option value="percent8">8th Place Percent</option>
                                        <option value="name9">9th Place Name</option>
                                        <option value="votes9">9th Place Votes</option>
                                        <option value="percent9">9th Place Percent</option>
                                        <option value="total">Total Votes</option>
                                    </form-select>
                                    <form-validation param="name" :errors="errors" />
                                </form-field>
                                <form-field v-if="tag_value.name !== ''">
                                    <form-input label="Default Value" id="res_default" name="res_default" v-model="tag_value.default_value"></form-input>
                                    <form-validation param="default_value" :errors="errors" />
                                </form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="$emit('add', tag_value); ">
                            {{ actionText }}
                        </form-button>
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'
    import FormSelect from '../../shared/form/Select'
    import FormInput from '../../shared/form/Input'
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'polling-locator-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormButton,
            FormField,
            FormLabel,
            FormSelect,
            FormInput,
            FormValidation,
        },
        emits: [
            'insert',
            'cancel',
        ],
        props: {
            attributes: Array,
            errors: Object,
            tag: {
                type: Object,
                default: {
                    name: '',
                    default_value: '',
                },
            },
            actionText: {
                type: String,
                default: 'Insert',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            tag_value: {
                get() {
                    return this.tag
                },
                set(val) {
                    this.$emit('update:tag', val);
                }
            },
        },
    };
</script>
