<template>
    <div>
        <layout-header title="Create New Dial List"></layout-header>

        <form-section title="Dial List Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="diallist.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="diallist.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>
        </form-section>

        <form-section title="Numbers">
            <form-field wide>
                <form-validation param="numbers" :errors="errors" />
            </form-field>

            <form-field wide>
                <div class="border-solid border-2 border-gray-300 bg-gray-100 mb-4 mr-4 p-4">
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                                <form-label for="numbers_0_number" label="Number"></form-label>
                            </div>
                            <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                                <form-label for="numbers_0_label" label="Label"></form-label>
                            </div>
                            <div class="w-full md:w-1/5 cursor-pointer h-full pl-6 text-left"></div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6" v-for="(number, index) in diallist.numbers" v-bind:key="index">
                            <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                                <form-input :id="'numbers_'+index+'_number'" :name="'numbers_'+index" label="" placeholder="+15555555555" v-model="diallist.numbers[index].number"></form-input>
                                <form-validation :param="'numbers.'+index+'.number'" :errors="errors" />
                            </div>
                            <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                                <form-input :id="'numbers_'+index+'_label'" :name="'numbers_'+index" placeholder="Label" v-model="diallist.numbers[index].label"></form-input>
                                <form-validation :param="'numbers.'+index+'.label'" :errors="errors" />
                            </div>
                            <div class="w-full md:w-1/5 cursor-pointer h-full pl-6 text-left">
                                <form-button negative-action @click="removeNumber(index)"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                            </div>
                        </div>

                        <div class="flex flex-wrap mx-3 mb-6">
                            <form-button positive-action @click="addNumber()"><i class="fas fa-plus-circle"></i> ADD</form-button>
                            <form-button positive-action @click="showImportDialog()"><i class="fas fa-plus-square"></i> IMPORT</form-button>
                        </div>
                </div>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Create Dial List
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>

        <DialListImportModal
            v-model:importType="import_type"
            v-model:importText="import_text"
            v-model:importData="import_data"
            v-model:importFiles="import_files"
            :show="open_import_modal"
            @import="importNumbers()"
            @cancel="open_import_modal = false;"
        ></DialListImportModal>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import DialListImportModal from '../../shared/modal/DialListImportModal';

    export default {
        name: 'CreateDialList',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            DialListImportModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            if (this.id !== null) {
                this.fetchDialList()
            }
        },
        computed: {
            id () {
                return this.$route.params.id || null;
            }
        },
        data() {
            return {
                diallist: {
                  name: '',
                  numbers: [{label: '', number: ''}],
                  status: 'active'
                },
                errors: [],
                import_type: 'csv_text',
                import_text: '',
                import_data: 'overwrite',
                import_files: null,
                show_help_section: null,
                open_import_modal: false,
            }
        },
        methods: {
            fetchDialList() {
                this.$http.get(`/api/v1/diallists/${this.id}`).then(this.refreshDialList);
            },
            refreshDialList({data}) {
                this.diallist = data.data;
            },
            save() {
              this.$http.post('/api/v1/diallists', this.diallist).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The dial list has been created',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_diallists'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred creating the dial list',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addNumber() {
                this.diallist.numbers.push({
                    number: '',
                    label: '',
                });
            },
            removeNumber(index) {
                this.diallist.numbers.splice(index, 1);
            },
            showImportDialog() {
                this.open_import_modal = true;
            },
            importNumbers() {
                let parent = this

                if (this.import_data === 'overwrite') {
                    this.diallist.numbers = []
                }

                if (this.import_type === 'csv_file') {
                    for (const file of this.import_files) {
                        this.$papa.parse(file, {
                            header: false,
                            complete: function (result) {
                                parent.processImportRows(result)
                                parent.open_import_modal = false;
                            },
                            error: function (error) {
                                console.log(error)
                            },
                        })
                    }
                } else if(this.import_type === 'csv_text') {
                    this.$papa.parse(this.import_text, {
                        header: false,
                        complete: function (result) {
                            parent.processImportRows(result)
                            parent.open_import_modal = false;
                        }
                    })
                }
            },
            processImportRows(result) {
                this.errors['numbers'] = []

                if (result.data.length === 1) {
                    for (let index in result.data[0]) {
                        this.diallist.numbers.push({
                            label: '',
                            number: this.formatNumber(result.data[0][index], index),
                        })
                    }
                } else if (result.data.length > 1) {
                    for (let index in result.data) {
                        if (result.data[index].length >= 2) {
                            this.diallist.numbers.push({
                                label: result.data[index][1],
                                number: this.formatNumber(result.data[index][0], index),
                            })
                        } else if (result.data[index].length === 1) {
                            this.diallist.numbers.push({
                                label: '',
                                number: this.formatNumber(result.data[index][0], index),
                            })
                        }
                    }
                }
            },
            formatNumber(number, index) {
                number = number.replace(/\D+/g, '')

                if (number.length === 10) {
                    number = '+1' + number
                } else if(number.length === 11 && number[0] === '1') {
                    number = '+' + number
                } else if(number.length === 12 && number[0] === '+' && number[1] === '1') {
                    number = number
                } else if(number.length < 10 || number.length >= 11) {
                    this.errors['numbers.'+index+'.number'] = ['Please only enter valid US numbers, in the format of +15555555555']
                }

                return number
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
