<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Search Available Numbers</DialogTitle>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">Search for available numbers to allocate for use.</p>
                            </div>
                            <div class="mt-8">
                                <form-field>
                                    <form-select id="number_type" label="Number Type" name="number_type" v-model="number_type" @change="updateNumberType()">
                                        <option value="local">Local Number</option>
                                        <option value="tollfree">TollFree Number</option>
                                    </form-select>
                                </form-field>
                                <form-field>
                                    <form-select id="search_type" label="Search Type" name="search_type" v-model="search_type">
                                        <option value="">- Select Search Type -</option>
                                        <option value="area_code" v-if="number_type === 'local'">By Area Code</option>
                                        <option value="state" v-if="number_type === 'local'">By State</option>
                                        <option value="contains">Contains Numbers</option>
                                    </form-select>
                                </form-field>
                                <form-field v-if="search_type !== ''">
                                    <form-input id="search_text" name="search_text" label="Search Text" v-model="search_text"></form-input>
                                </form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="$emit('search')">
                            {{ actionText }}
                        </form-button>
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'
    import FormSelect from '../../shared/form/Select'
    import FormInput from '../../shared/form/Input'

    export default {
        name: 'embed-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            ExclamationTriangleIcon,
            FormButton,
            FormField,
            FormLabel,
            FormSelect,
            FormInput,
        },
        emits: [
            'search',
            'cancel',
            'update:numberType',
            'update:searchType',
            'update:searchText',
        ],
        props: {
            name: String,
            numberType: String,
            searchType: String,
            searchText: String,
            actionText: {
                type: String,
                default: 'Search',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            number_type: {
                get() {
                    return this.numberType
                },
                set(val) {
                    this.$emit('update:numberType', val);
                }
            },
            search_type: {
                get() {
                    return this.searchType
                },
                set(val) {
                    this.$emit('update:searchType', val);
                }
            },
            search_text: {
                get() {
                    return this.searchText
                },
                set(val) {
                    this.$emit('update:searchText', val);
                }
            },
        },
        methods: {
            updateNumberType() {
                this.search_type = ''
                this.search = ''
            },
        },
    };
</script>
