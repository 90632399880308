<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('close')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Available Representatives</DialogTitle>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">Searching {{ level }} level, {{ type }}.  Found {{ repSummary.length }} results.</p>
                            </div>
                            <div class="mt-8">
                                <form-field wide>
                                    <div class="pb-2 overflow-y-scroll" style="height:400px">
                                        <p v-for="rep in repSummary" v-bind:key="rep.id">
                                            <strong>{{ rep.full_name }} ({{ rep.party }})</strong>
                                            <br />{{ rep.phone }}
                                        </p>
                                    </div>
                                </form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="$emit('close'); ">
                            {{ actionText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'

    export default {
        name: 'rep-lookup-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormButton,
            FormField,
            FormLabel,
        },
        emits: [
            'close',
        ],
        props: {
            level: {
                type: String,
            },
            type: {
                type: String,
            },
            repSummary: {
                type: Array,
            },
            actionText: {
                type: String,
                default: 'Close',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
