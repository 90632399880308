<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Import Numbers</DialogTitle>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">Import numbers from a CSV file or text string.</p>
                            </div>
                            <div class="mt-8">
                                <form-field>
                                    <form-select id="import_type" name="import_type" label="Import Type" v-model="import_type">
                                        <option value="csv_file">CSV File</option>
                                        <option value="csv_text">CSV String</option>
                                    </form-select>
                                </form-field>
                                <form-field v-if="import_type === 'csv_file'">
                                    <form-file name="import_files" id="import_files" label="CSV File" @change="(files) => { import_files = files }"></form-file>
                                </form-field>
                                <form-field v-if="import_type === 'csv_text'">
                                    <form-textarea name="import_text" id="import_text" rows="5" label="Numbers" v-model="import_text"></form-textarea>
                                </form-field>
                                <form-field>
                                    <form-select id="import_data" name="import_data" label="Import Method" v-model="import_data">
                                        <option value="add">Add to Current Numbers</option>
                                        <option value="overwrite">Overwrite Current Numbers</option>
                                    </form-select>
                                </form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="$emit('import')">
                            {{ actionText }}
                        </form-button>
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'
    import FormSelect from '../../shared/form/Select'
    import FormInput from '../../shared/form/Input'
    import FormTextarea from '../../shared/form/TextArea'
    import FormFile from '../../shared/form/File'

    export default {
        name: 'diallist-import-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            ExclamationTriangleIcon,
            FormButton,
            FormField,
            FormLabel,
            FormSelect,
            FormInput,
            FormTextarea,
            FormFile,
        },
        emits: [
            'import',
            'cancel',
            'update:importType',
            'update:importText',
            'update:importData',
            'update:importFiles',
        ],
        props: {
            name: String,
            importType: String,
            importText: String,
            importData: String,
            importFiles: FileList,
            actionText: {
                type: String,
                default: 'Import',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            import_type: {
                get() {
                    return this.importType
                },
                set(val) {
                    this.$emit('update:importType', val);
                }
            },
            import_text: {
                get() {
                    return this.importText
                },
                set(val) {
                    this.$emit('update:importText', val);
                }
            },
            import_data: {
                get() {
                    return this.importData
                },
                set(val) {
                    this.$emit('update:importData', val);
                }
            },
            import_files: {
                get() {
                    return this.importFiles
                },
                set(val) {
                    this.$emit('update:importFiles', val);
                }
            },
        },
    };
</script>
