<template>
    <div>
        <layout-header :title="'Viewing Call '+call.id">
        </layout-header>
    </div>

    <div class="flex m-5">
        <div class="w-1/5 mr-2">
            <div class="my-6 py-4 px-6 rounded-lg border-2 border-gray-200 shadow-md">
                <h5 class="pb-4 font-bold uppercase text-sm">Call Details</h5>
                <div class="w-5/6 pb-3 text-left text-sm text-left">Created At<br /><span class="text-xs text-gray-700">{{ $filters.formatDate(call.created_at)  }}</span></div>
                <div class="w-5/6 pb-3 text-left text-sm text-left">Direction<br /><span class="text-xs text-gray-700">{{ $filters.capitalize(call.direction) }}</span></div>
                <div class="w-5/6 pb-3 text-left text-sm text-left">Duration<br /><span class="text-xs text-gray-700">{{ call.duration }} minute{{ call.duration !==  1 ? 's' : '' }}</span></div>
                <div class="w-5/6 pb-3 text-left text-sm text-left">Status<br /><span class="text-xs text-gray-700">{{ $filters.capitalize(call.status) }}</span></div>
            </div>

            <div class="my-6 rounded-lg border-2 border-gray-200 shadow-md" v-if="call.parent_call !== null">
                <h5>Initiated Call</h5>
                <div class="flex p-2">
                    <div class="w-5/6 text-left text-sm text-left"><a :href="'/call/view/' + call.parent_call.id">Call {{ call.parent_call.id }}</a><br /><span class="text-xs text-gray-700">{{ $filters.formatDate(call.parent_call.created_at) }}</span></div>
                </div>
            </div>

            <!--<div class="m-5 bg-white shadow-md rounded my-6 p-2" v-if="call.recordings.length > 0">
                <h5>Recordings</h5>
                <div v-for="recording in call.recordings" v-bind:key="recording.id">
                    <div class="flex p-2" >
                        <div class="w-4/6 text-left text-sm text-left">{{ recording.sequence.name }} ({{ recording.recording_status }})<br /><span class="text-sm text-gray-700">{{ recording.created_at }}</span></div>
                        <div class="w-1/6 text-gray-700 text-right text-sm text-right" @click="confirmRemoveRecording(recording.id)"><i class="fas fa-trash"></i></div>
                    </div>
                    <div class="flex p-2" >
                        <div class="w-full text-left text-sm text-left">
                            <audio controls :src="'api/recordings/'+recording.id+'/download'"></audio>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>
        <div class="w-4/5 ml-2">
            <table-table>
                <template v-slot:header>
                    <table-row type="header">
                        <table-header hide-sm>Request Details</table-header>
                        <table-header hide-sm>Response Details</table-header>
                        <table-header>Created At</table-header>
                    </table-row>
                </template>
                <template v-slot:body>
                    <table-row v-for="(action, index) in call.actions" :key="action.id" :index="index">
                        <table-cell hide-sm>{{ getCallRequestDetails(action.request, index) }}</table-cell>
                        <table-cell hide-sm>
                            <span v-if="action.request.status === 'api-initiated'">System queues up the following when call is answered:</span>
                            <ul class="list-disc list-inside text-sm">
                                <li v-for="(response, index) in action.response" v-bind:key="index" v-html="getCallResponseDetails(response, index)"></li>
                            </ul>
                        </table-cell>
                        <table-cell>{{$filters.formatDateSeconds(action.created_at)}}</table-cell>
                    </table-row>
                </template>
            </table-table>

            <div class="m-5 bg-white shadow-md rounded my-6 p-2" v-if="call.params && call.params.rep_id">
                <h5>Connected Rep</h5>

                <div class="flex p-2">
                    <div class="w-5/6 text-left text-sm text-left">Name<br /><span class="text-xs text-gray-700">{{ call.params.rep.full_name }}</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'
    import TableTable from '../../shared/table/Table'
    import TableRow from '../../shared/table/Row'
    import TableHeader from '../../shared/table/Header'
    import TableCell from '../../shared/table/Cell'

    export default {
        name: 'ViewCall',
        components: {
            LayoutApp,
            LayoutHeader,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                call: {
                    child_calls:[],
                    parent_call: null,
                },
            }
        },
        methods: {
            fetch() {
                this.$http.get(`/api/v1/calls/${this.id}`).then(this.refresh);
            },
            refresh({data}) {
                this.call = data.data;
                window.scrollTo(0,0);
            },
            getCallRequestDetails(request, index) {
                if (request.direction === 'inbound' || request.direction === 'outbound-api') {
                    if (request.transcription_status !== null) {
                        if (request.transcription_status === 'completed') {
                            return 'Recording transcription saved as "'+request.transcription_text+'"'
                        } else if (request.transcription_status === 'failed') {
                            return 'Recording transcription failed.'
                        } else {
                            return 'Recording transcription status unknown.'
                        }
                    } else if (request.status === 'api-initiated') {
                        return 'API call initiated from system.'
                    } else if (request.status === 'initiated') {
                        return 'Call made from ' + request.from + ' to ' + request.to + ' by way of API call.'
                    } else if (request.status === 'ringing') {
                        return 'Call from ' + request.from + ' to ' + request.to + ' ringing.'
                    } else if (request.status === 'in-progress' && request.speech_result !== null) {
                        return 'Caller said "' + request.speech_result + '" with ' + (Number(request.confidence) * 100) + '% confidence' + (request.finished_on_key !== null ? ', followed by "' + request.finished_on_key + '" key to finish.' : '.')
                    } else if (request.status === 'in-progress' && request.digits !== null) {
                        return 'Caller entered digits "' + request.digits + (request.finished_on_key !== null ? '", followed by "' + request.finished_on_key + '" to finish.' : '".')
                    } else if (request.status === 'in-progress') {
                        if (this.call.actions[index - 1] && this.call.actions[index - 1].response.length > 0 && this.call.actions[index - 1].response[this.call.actions[index - 1].response.length - 1].verb === 'dial') {
                            return 'Call returns from outbound dial.'
                        } else if (this.call.actions[index - 1] && this.call.actions[index - 1].request.status === 'ringing') {
                            return 'Call answered.'
                        } else {
                            return 'Call continues.'
                        }
                    } else if (request.status === 'completed') {
                        return 'Call completes.'
                    }
                } else if (request.direction === 'outbound-dial') {
                    return 'Call made from ' + request.from + ' to ' + request.to + (this.call.called_via !== null ? ' by way of ' + this.call.called_via : '') + '.'
                } else if (request.recording_status === 'in-progress') {
                    return 'Caller begins recording message.'
                } else if (request.recording_status === 'completed') {
                    return 'Recorded message processed and saved as <a href="'+request.recording_url+'" target="_blank">file</a>.'
                }
            },
            getCallResponseDetails(response, request, index) {
                if (response.verb === 'say') {
                    return 'System says "' + response.text + '" in voice "' + response.voice + '" and language ' + this.getFriendlyLanguage(response.language) + '.'
                } else if (response.verb === 'gather' && response.prompt_verb === 'say') {
                    return 'System prompts with "' + response.prompt + '" in voice "' + response.prompt_voice + '" and language ' + this.getFriendlyLanguage(response.prompt_language) + '.'
                } else if (response.verb === 'gather' && response.prompt_verb === 'play') {
                    return 'System plays <a href="' + response.prompt_url + '">file</a> for ' + response.prompt_loop + ' time' + (response.prompt_loop !== 1 ? 's' : '') + '.'
                } else if (response.verb === 'dial') {
                    return 'System dials the following numbers: ' + response.numbers.join(', ') + '.  ' + (response.dialed_call !== null ? ' <a href="/call/view/'+response.dialed_call.id+'">Caller connected to '+ response.dialed_call.recipient_number + '</a>.' : '')
                } else if (response.verb === 'pause') {
                    return 'System pauses for ' + response.length + ' seconds.'
                } else if (response.verb === 'hangup') {
                    return 'System hangs up the call.'
                } else if (response.verb === 'play') {
                    return 'System plays audio <a href="'+response.url+'" target="_blank">file</a>.'
                } else {
                    return 'n/a'
                }
            },
            getFriendlyLanguage(language) {
                if (language === 'en-US') {
                    return 'English (US)'
                } else if (language === 'en') {
                    return 'English'
                } else {
                    return language
                }
            }
        }
    }
</script>
