import { createRouter, createWebHistory } from 'vue-router';

import Home from './components/app/Home';

import ListNumbers from './components/app/numbers/ListNumbers';
import CreateNumber from './components/app/numbers/CreateNumber';
import EditNumber from './components/app/numbers/EditNumber';

import ListSegments from './components/app/segments/ListSegments';
import CreateSegment from './components/app/segments/CreateSegment';
import EditSegment from './components/app/segments/EditSegment';

import ListSequences from './components/app/sequences/ListSequences';
import CreateSequence from './components/app/sequences/CreateSequence';
import EditSequence from './components/app/sequences/EditSequence';

import ListWebforms from './components/app/webforms/ListWebforms';
import CreateWebform from './components/app/webforms/CreateWebform';
import EditWebform from './components/app/webforms/EditWebform';

import ListAttributes from './components/app/attributes/ListAttributes';
import CreateAttribute from './components/app/attributes/CreateAttribute';
import EditAttribute from './components/app/attributes/EditAttribute';

import ListDialLists from './components/app/diallists/ListDialLists';
import CreateDialList from './components/app/diallists/CreateDialList';
import EditDialList from './components/app/diallists/EditDialList';

import ListReports from './components/app/reports/ListReports';
import CreateReport from './components/app/reports/CreateReport';
import EditReport from './components/app/reports/EditReport';

import ListPeople from './components/app/people/ListPeople';
import ViewPerson from './components/app/people/ViewPerson';

import ListCalls from './components/app/calls/ListCalls';
import ViewCall from './components/app/calls/ViewCall';

import ListRecordings from './components/app/recordings/ListRecordings';

import NotFound from './components/app/NotFound';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            component: NotFound
        },
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/numbers',
            name: 'list_numbers',
            component: ListNumbers
        },
        {
            path: '/numbers/create',
            name: 'create_number',
            component: CreateNumber
        },
        {
            path: '/numbers/edit/:id',
            name: 'edit_number',
            component: EditNumber,
            props: true
        },
        {
            path: '/segments',
            name: 'list_segments',
            component: ListSegments
        },
        {
            path: '/segments/create',
            name: 'create_segment',
            component: CreateSegment
        },
        {
            path: '/segments/edit/:id',
            name: 'edit_segment',
            component: EditSegment,
            props: true
        },
        {
            path: '/sequences',
            name: 'list_sequences',
            component: ListSequences
        },
        {
            path: '/sequences/create',
            name: 'create_sequence',
            component: CreateSequence
        },
        {
            path: '/sequences/edit/:id',
            name: 'edit_sequence',
            component: EditSequence,
            props: true
        },
        {
            path: '/webforms',
            name: 'list_webforms',
            component: ListWebforms
        },
        {
            path: '/webforms/create',
            name: 'create_webform',
            component: CreateWebform
        },
        {
            path: '/webforms/edit/:id',
            name: 'edit_webform',
            component: EditWebform,
            props: true
        },
        {
            path: '/attributes',
            name: 'list_attributes',
            component: ListAttributes
        },
        {
            path: '/attributes/create',
            name: 'create_attribute',
            component: CreateAttribute
        },
        {
            path: '/attributes/edit/:id',
            name: 'edit_attribute',
            component: EditAttribute,
            props: true
        },
        {
            path: '/diallists',
            name: 'list_diallists',
            component: ListDialLists
        },
        {
            path: '/diallists/create',
            name: 'create_diallist',
            component: CreateDialList
        },
        {
            path: '/diallists/create/:id',
            name: 'duplicate_diallist',
            component: CreateDialList
        },
        {
            path: '/diallists/edit/:id',
            name: 'edit_diallist',
            component: EditDialList,
            props: true
        },
        {
            path: '/reports',
            name: 'list_reports',
            component: ListReports
        },
        {
            path: '/reports/create',
            name: 'create_report',
            component: CreateReport
        },
        {
            path: '/reports/edit/:id',
            name: 'edit_report',
            component: EditReport,
            props: true
        },
        {
            path: '/people',
            name: 'list_people',
            component: ListPeople
        },
        {
            path: '/people/view/:id',
            name: 'view_person',
            component: ViewPerson,
            props: true
        },
        {
            path: '/calls',
            name: 'list_calls',
            component: ListCalls
        },
        {
            path: '/call/view/:id',
            name: 'view_call',
            component: ViewCall,
            props: true
        },
        {
            path: '/recordings',
            name: 'list_recordings',
            component: ListRecordings
        },
    ]
});

export default router;
