<template>
    <div>
        <layout-header title="Home"></layout-header>

        <div class="flex flex-col m-5 bg-white">
            <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md" v-if="home_data">
                <div class="">
                    <div class="bg-white my-2">
                        <div class="px-2 py-2">
                            <div class="flex">
                                <div class="flex-1 font-bold text-xl mb-2">{{ home_data.title }}</div>
                                <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="closeUpdateNotification()"><i class="far fa-times-circle"></i></a></h2>
                            </div>
                            <span v-html="home_data.notes_html"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md">
                <div class="">
                    <div class="bg-white my-6">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Total Calls/Minutes</div>
                            <line-chart
                                :data="total_calls_minutes"
                                :library="{hAxis: {format: 'MMM d'}}"
                                :colors="['#8368db', '#f96900']"
                            ></line-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-row">
                <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md w-1/2">
                    <div class="">
                        <div class="bg-white my-6">
                            <div class="px-6 py-4">
                                <div class="font-bold text-xl mb-2">Inbound/Outbound</div>
                                <pie-chart
                                    :data="direction_summary_data"
                                    :colors="['#8368db', '#f96900']"
                                ></pie-chart>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md w-1/2">
                    <div class="">
                        <div class="bg-white my-6">
                            <div class="px-6 py-4">
                                <div class="font-bold text-xl mb-2">Inbound Calls by State</div>
                                <geo-chart
                                    :data="state_summary_data"
                                    :library="{region: 'US', resolution: 'provinces'}"
                                    :colors="['#8368db', '#f96900']"
                                ></geo-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import moment from 'moment-timezone';
    import LayoutApp from '../layouts/App';
    import LayoutHeader from '../shared/layout/Header'

    export default {
        name: 'home',
        components: {
            LayoutApp,
            LayoutHeader,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetch();
            this.fetchTotalCallsMinutes();
            this.fetchDirectionSummary();
            this.fetchStateSummary();
        },
        data() {
            return {
                total_calls_minutes: null,
                direction_summary_data: null,
                state_summary_data: null,
                home_data: null,
            }
        },
        methods: {
            fetch() {
                this.$http.get(`/api/v1/home`).then(this.refresh);
            },
            reportUrl() {
                return `/api/v1/reports/view`;
            },
            fetchTotalCallsMinutes() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().format('YYYY-MM-DD');
                this.$http.get(this.reportUrl()+`/?type=total_calls_minutes&group_by=day&start=${start}&end=${end}`).then(this.refreshTotalCallsMinutes);
            },
            refreshTotalCallsMinutes({data}) {
                this.total_calls_minutes = data.data;
            },
            fetchDirectionSummary() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().add(1, 'days').format('YYYY-MM-DD');
                this.$http.get(this.reportUrl()+`/?type=direction_summary&group_by=month&start=${start}&end=${end}`).then(this.refreshDirectionSummary);
            },
            refreshDirectionSummary({data}) {
                this.direction_summary_data = data.data;
            },
            fetchStateSummary() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().add(1, 'days').format('YYYY-MM-DD');
                this.$http.get(this.reportUrl()+`/?type=state_summary&group_by=month&start=${start}&end=${end}`).then(this.refreshStateSummary);
            },
            refreshStateSummary({data}) {
                this.state_summary_data = data.data;
            },
        }
    }
</script>
