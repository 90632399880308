import moment from 'moment-timezone';

function capitalize(value) {
    if (!value)
        return value;

    let frags = value.split(/_|-/);
    for (let i = 0; i < frags.length; ++i) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

function formatDate(value) {
    if (value) {
        return moment(String(value)).tz(moment.tz.guess()).format('MM/DD/YYYY h:mma z');
    }
}

function formatDateSeconds(value) {
    if (value) {
        return moment(String(value)).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm:ssa z');
    }
}

function formatSeconds(seconds) {
    if (seconds) {
        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        return `${('0'+minutes).slice(-2)}:${('0'+seconds).slice(-2)}`;
    }
}

function formatMinutes(minutes) {
    if (minutes) {
        return `${('0'+minutes).slice(-2)}:00`;
    }
}

export { capitalize, formatDate, formatDateSeconds, formatSeconds, formatMinutes };
