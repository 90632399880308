<template>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <slot></slot>
    </dl>
</template>

<script>

    export default {
        name: 'layout-summary',
        props: {
        },
    };
</script>
