<style>
    input:checked + div {
        @apply border-sbr-purple;
    } input:hover + div {
        @apply border-sbr-purple;
    }
    input:checked + div svg {
        @apply block;
    }
</style>

<template>
    <div class="flex items-center mr-4">
        <input
            class="opacity-0 absolute h-4 w-4"
            :id="id"
            :name="name"
            type="checkbox"
            v-model="input_value"
            :value="value"
        />
        <div class="bg-white border rounded border-gray-400 w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-gray-500 ">
            <svg class="fill-current hidden w-3 h-3 text-sbr-purple pointer-events-none" version="1.1" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <g transform="translate(-9 -11)" fill="#8368db" fill-rule="nonzero">
                        <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                </g>
            </svg>
        </div>
        <label :for="id" class="select-none">{{ label }}</label>
    </div>
</template>

<script>
    export default {
        name: 'form-checkbox',
        model: {
            prop: "checked",
            event: "change"
        },
        props: {
            id: String,
            name: String,
            label: String,
            checked: {
                type: [Array, Boolean],
                default: false
            },
            value: {
                default: null,
            },
        },
        emits: [
            'change',
        ],
        computed: {
            input_value: {
                get() {
                    return this.checked
                },
                set(val) {
                    this.$emit('change', val)
                }
            },
        },
    }
</script>
