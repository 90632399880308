<template>
    <div>
        <layout-header title="Calls">
        </layout-header>

        <filter-filter cols.number="2">
            <filter-column>
                <form-input id="search" name="search" label="" v-model="search" placeholder="Search by sender/recipient and press ↵" @keyupenter="updateSearch()"></form-input>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_number_ids"
                    :options="numbers"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Number"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_directions"
                    :options="directions"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Direction"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_statuses"
                    :options="statuses"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Status"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
        </filter-filter>

        <table-table>
            <template v-slot:header>
                <table-row type="header">
                    <table-header @click="updateSort('id')" hide-sm>ID</table-header>
                    <table-header @click="updateSort('sender_number')">Sender</table-header>
                    <table-header @click="updateSort('recipient_number')">Recipient</table-header>
                    <table-header @click="updateSort('direction')">Direction</table-header>
                    <table-header @click="updateSort('status')" hide-sm>Status</table-header>
                    <table-header @click="updateSort('duration')">Duration</table-header>
                    <table-header @click="updateSort('created_at')">Created At</table-header>
                    <table-header type="text" last>Actions</table-header>
                </table-row>
            </template>
            <template v-slot:body>
                <table-row v-for="(call, index) in calls" :key="call.id" :index="index">
                    <table-cell hide-sm bold>{{ call.id }}</table-cell>
                    <table-cell>{{ call.sender_number }}</table-cell>
                    <table-cell>{{ call.recipient_number }}</table-cell>
                    <table-cell>{{ $filters.capitalize(call.direction) }}</table-cell>
                    <table-cell>{{ $filters.capitalize(call.status) }}</table-cell>
                    <table-cell>{{ $filters.formatMinutes(call.duration) }}</table-cell>
                    <table-cell>{{ $filters.formatDate(call.created_at) }}</table-cell>
                    <table-cell last>
                        <router-link :to="{ name: 'view_call', params: { id: call.id }}" class="mx-3"><i class="fas fa-id-card"></i></router-link>
                    </table-cell>
                </table-row>
            </template>
        </table-table>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'
    import LayoutButton from '../../shared/layout/Button'
    import FilterFilter from '../../shared/filter/Filter'
    import FilterColumn from '../../shared/filter/Column'
    import TableTable from '../../shared/table/Table'
    import TableRow from '../../shared/table/Row'
    import TableHeader from '../../shared/table/Header'
    import TableCell from '../../shared/table/Cell'
    import TableStatusBadge from '../../shared/table/StatusBadge'
    import TableEditLink from '../../shared/table/EditLink'
    import TableDeleteLink from '../../shared/table/DeleteLink'
    import FormField from '../../shared/form/Field'
    import FormInput from '../../shared/form/Input'
    import FormSelect from '../../shared/form/Select'

    export default {
        name: 'ListCalls',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            FilterFilter,
            FilterColumn,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
            TableStatusBadge,
            TableEditLink,
            TableDeleteLink,
            FormField,
            FormInput,
            FormSelect,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetch(1)
            this.fetchNumbers()
        },
        data() {
            return {
                calls: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                numbers: [],
                directions: [
                    {id: 'inbound', name: 'Inbound'},
                    {id: 'outbound', name: 'Outbound'},
                    {id: 'outbound-api', name: 'Outbound API'},
                    {id: 'outbound-dial', name: 'Outbound Dial'},
                ],
                statuses: [
                    {id: 'queued', name: 'Queued'},
                    {id: 'ringing', name: 'Ringing'},
                    {id: 'in-progress', name: 'In Progress'},
                    {id: 'completed', name: 'Completed'},
                    {id: 'busy', name: 'Busy'},
                    {id: 'failed', name: 'Failed'},
                    {id: 'no-answer', name: 'No Answer'},
                ],
                search: this.$route.query.search || '',
                search_number_ids: [],
                search_directions: [],
                search_statuses: [],
                search_sources: [],
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_number_ids = this.search_number_ids.map(number => number.id)
                let search_directions = this.search_directions.map(direction => direction.id)
                let search_statuses = this.search_statuses.map(status => status.id)

                this.$http.get(`/api/v1/calls?page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_number_ids=${search_number_ids}&search_directions=${search_directions}&search_statuses=${search_statuses}`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.calls = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            fetchNumbers() {
                this.$http.get(`/api/v1/numbers?per_page=-1`).then(this.refreshNumbers);
            },
            refreshNumbers({data}) {
                this.numbers = data.data;
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
        }
    }
</script>
