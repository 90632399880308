<template>
    <div>
        <form-label :id="id" :label="label"></form-label>
        <input
            class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-full shadow-sm py-2 px-4 mb-3 focus:outline-none focus:bg-white focus:border-sbr-purple focus:ring-sbr-purple"
            :id="id"
            :name="name"
            :readonly="readonly"
            :disabled="disabled"
            type="file"
            @change="inputFileChange($event)"
        >
    </div>
</template>

<script>
    import FormLabel from './Label'

    export default {
        name: 'form-file',
        components: {
            FormLabel,
        },
        props: {
            id: String,
            name: String,
            label: String,
            required: Boolean,
            readonly: Boolean,
            disabled: Boolean,
        },
        emits: [
            'change',
        ],
        methods: {
            inputFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;

                this.$emit('change', files);
            }
        },
    }
</script>
