<template>
    <div>
        <layout-header title="Create New Sequence"></layout-header>

        <form-section title="Sequence Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="sequence.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="sequence.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-select class="form-select" id="number_id" name="number_id" label="Outbound Number" v-model="sequence.number_id">
                    <option :value="null">- Select Number -</option>
                    <option v-for="number in numbers" v-bind:key="number.id" :value="number.id">{{ number.name }} ({{ number.number }})</option>
                </form-select>
                <form-validation param="number_id" :errors="errors" />
            </form-field>
        </form-section>

        <form-section title="Sequence Builder">
            <form-field wide>
                <div class="">
                    <div class="flex w-full">
                        <!-- Builder -->
                        <div class="w-1/3 pb-4 overflow-hidden">
                            <div class="mb-8">
                                <Builder :sequence="sequence" :current_block="current_block" :set-active-block="setActiveBlock" :remove-block="removeBlock"></Builder>
                            </div>

                            <div class="px-3">
                                <form-button positive-action @click="addBlockDialog();"><i class="fas fa-plus-circle"></i> ADD</form-button>
                            </div>
                        </div>

                        <div class="w-2/3 pb-4 overflow-hidden">
                            <div v-if="current_block !== null" class="p-4 mx-6 bg-gray-100 border-2 rounded-lg border-sbr-purple">
                                <!-- Speak Block -->
                                <div v-if="current_block.messaging_type === 'speak'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Speak Block</div>
                                    </div>
                                    <div class="mb-2">
                                        <p class="text-sm italic text-gray-500">Read text back to the caller.</p>
                                    </div>
                                    <form-validation param="params" :errors="current_block.errors" />

                                    <form-section clear>
                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Text"
                                                name="block_speak_text"
                                                max="4096"
                                                v-model="current_block.params.message"
                                                :attributes="all_attributes">
                                            </dynamic-textarea>
                                            <form-validation param="params.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select id="block_speak_language" name="block_speak_language" label="Language" v-model="current_block.params.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select id="block_speak_voice" name="block_speak_voice" label="Voice" v-model="current_block.params.voice">
                                                <option :value="null">- Select Voice -</option>
                                                <optgroup label="Standard">
                                                    <option value="Man" v-if="current_block.params.language === 'en-US' || current_block.params.language === 'en-GB' || current_block.params.language === 'es-MX'">Man</option>
                                                    <option value="Woman" v-if="current_block.params.language === 'en-US' || current_block.params.language === 'en-GB' || current_block.params.language === 'es-MX'">Woman</option>
                                                </optgroup>
                                                <optgroup label="Premium">
                                                    <option value="Polly.Ivy" v-if="current_block.params.language === 'en-US'">Ivy</option>
                                                    <option value="Polly.Joanna" v-if="current_block.params.language === 'en-US'">Joanna</option>
                                                    <option value="Polly.Joey" v-if="current_block.params.language === 'en-US'">Joey</option>
                                                    <option value="Polly.Justin" v-if="current_block.params.language === 'en-US'">Justin</option>
                                                    <option value="Polly.Kendra" v-if="current_block.params.language === 'en-US'">Kendra</option>
                                                    <option value="Polly.Kimberly" v-if="current_block.params.language === 'en-US'">Kimberly</option>
                                                    <option value="Polly.Matthew" v-if="current_block.params.language === 'en-US'">Matthew</option>
                                                    <option value="Polly.Salli" v-if="current_block.params.language === 'en-US'">Salli</option>
                                                    <option value="Polly.Nicole" v-if="current_block.params.language === 'en-AU'">Nicole</option>
                                                    <option value="Polly.Russell" v-if="current_block.params.language === 'en-AU'">Russell</option>
                                                    <option value="Polly.Amy" v-if="current_block.params.language === 'en-GB'">Amy</option>
                                                    <option value="Polly.Brian" v-if="current_block.params.language === 'en-GB'">Brian</option>
                                                    <option value="Polly.Emma" v-if="current_block.params.language === 'en-GB'">Emma</option>
                                                    <option value="Polly.Mia" v-if="current_block.params.language === 'es-MX'">Mia</option>
                                                    <option value="Polly.Chantal" v-if="current_block.params.language === 'fr-CA'">Chantal</option>
                                                </optgroup>
                                            </form-select>
                                            <form-validation param="params.voice" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Play Block -->
                                <div v-if="current_block.messaging_type === 'play'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Play Block</div>
                                    </div>
                                    <div class="mt-2 mb-6">
                                        <p class="text-sm italic text-gray-500">Play audio back to the caller.</p>
                                    </div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select name="block_play_play_settings_audio_type" id="block_play_play_settings_audio_type" label="Audio Type" v-model="current_block.play_settings.default.audio_type" :disabled="current_block.params.attachment_key !== null">
                                                <option :value="null">- Select Audio Type -</option>
                                                <option value="file">File Upload</option>
                                                <option value="record">Record Audio</option>
                                            </form-select>
                                        </form-field>

                                        <form-field>
                                            <div v-if="current_block.params.attachment_key === null">
                                                <div v-if="current_block.play_settings.default.audio_type === 'file'">
                                                    <form-file id="block_play_audio_file" name="block_play_audio_file" ref="file" label="Audio File" @change="handleFileUpload" />
                                                    <form-validation param="params.attachment_key" :errors="current_block.errors"></form-validation>
                                                    <form-validation param="file" :errors="current_block.errors"></form-validation>

                                                    <div class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="submitFile()"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                                <div v-if="current_block.play_settings.default.audio_type === 'record'">
                                                    <label class="form-label" for="block_play_audio_record">Record Audio</label>
                                                    <div class="flex mx-3 mb-6 flex-nowrap">
                                                        <div class="w-10 h-10 mt-2 mr-2">
                                                            <button id="block_play_audio_record" v-bind:class="{'bg-green-500 hover:bg-green-800': (current_block.play_settings.default.status === 'waiting'), 'bg-red-500 hover:bg-red-800': (current_block.play_settings.default.status === 'recording')}" class="inline-flex items-center justify-center w-10 h-10 text-white transition-colors duration-150 rounded-full focus:shadow-outline" type="button" @click="startStopRecording('default')">
                                                                <i v-if="current_block.play_settings.default.status === 'waiting'" class="fas fa-microphone"></i>
                                                                <i v-if="current_block.play_settings.default.status === 'recording'" class="far fa-stop-circle"></i>
                                                            </button>
                                                        </div>

                                                        <div class="w-full mt-2">
                                                            <audio v-if="current_block.play_settings.default.audio_url" :src="current_block.play_settings.default.audio_url" controls class="w-full" />
                                                        </div>
                                                    </div>

                                                    <div v-if="current_block.play_settings.default.audio_url" class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="saveFile('default')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <audio v-if="current_block.params.attachment_key !== null" controls class="pb-2">
                                                    <source :src="'/attachments/'+current_block.params.attachment_key" :type="current_block.params.attachment_mimetype" />
                                                </audio>

                                                <div class="flex flex-wrap mx-3 mb-6 text-right">
                                                    <form-button negative-action @click="removeFile()"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                                                </div>
                                            </div>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Dial Block -->
                                <div v-if="current_block.messaging_type === 'dial'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Dial Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic text-gray-500">Connect the caller to another party.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Dial Type" id="block_dial_type" name="block_dial_type" v-model="current_block.params.type">
                                                <option :value="null">- Select Dial Type -</option>
                                                <option value="first">First</option>
                                                <option value="first_answer">First to Answer</option>
                                                <option value="random">Random</option>
                                            </form-select>
                                            <form-validation param="params.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Dial Source" id="block_dial_source" name="block_dial_source" v-model="current_block.params.source" @change="updateDialSource()">
                                                <option :value="null">- Select Dial Source -</option>
                                                <option value="numbers">Numbers</option>
                                                <option value="dial_list">Dial List</option>
                                            </form-select>
                                            <form-validation param="params.source" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <div v-if="current_block.params.source === 'dial_list'">
                                                <form-select label="Dial List" id="block_dial_list_id" name="block_dial_list_id" v-model="current_block.params.dial_list_id">
                                                    <option :value="null">- Select Dial List -</option>
                                                    <option v-for="dial_list in dial_lists" v-bind:key="dial_list.id" :value="dial_list.id">{{ dial_list.name }}</option>
                                                </form-select>
                                                <form-validation param="params.dial_list_id" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div v-if="current_block.params.source === 'numbers'">
                                                <form-label v-if="current_block.params.source === 'numbers'" label="Numbers"></form-label>
                                                <div class="w-full" v-if="current_block.params.source === 'numbers'">
                                                    <!-- Numbers -->
                                                    <div class="flex flex-wrap" v-for="(number, number_index) in current_block.params.numbers" v-bind:key="number_index">
                                                        <div class="w-full md:w-3/5">
                                                            <form-input :id="'block_dial_'+number_index+'_number'" :name="'block_dial_'+number_index+'_number'" v-model="current_block.params.numbers[number_index].number" />
                                                            <form-validation :param="'params.numbers.'+number_index" :errors="current_block.errors"></form-validation>
                                                            <form-validation :param="'params.numbers.'+number_index+'.number'" :errors="current_block.errors"></form-validation>
                                                        </div>

                                                        <div class="w-full h-full text-right cursor-pointer md:w-2/5">
                                                            <form-button negative-action @click="removeNumber(number_index)"><i class="fas fa-minus-circle"></i></form-button>
                                                        </div>
                                                    </div>

                                                    <div class="flex flex-wrap mx-3 mb-6">
                                                        <form-button positive-action @click="addNumber()"><i class="fas fa-plus-circle"></i> ADD</form-button>
                                                    </div>

                                                    <form-validation param="params.numbers" :errors="current_block.errors"></form-validation>
                                                </div>
                                            </div>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Rep Lookup Block -->
                                <div v-if="current_block.messaging_type === 'rep_lookup'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Representative Lookup</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Connect the caller with a representative.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Level" id="block_rep_lookup_level" name="block_rep_lookup_level" v-model="current_block.params.level">
                                                <option :value="null">- Select Level -</option>
                                                <option value="federal" v-if="settings.lookup.features.rep_lookup_federal">Federal</option>
                                                <option value="state" v-if="settings.lookup.features.rep_lookup_state">State</option>
                                            </form-select>
                                            <form-validation param="params.level" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Type" id="block_rep_lookup_type" name="block_rep_lookup_type" v-model="current_block.params.type">
                                                <option :value="null">- Select Type -</option>
                                                <option value="lower">Lower</option>
                                                <option value="upper">Upper</option>
                                                <option value="executive">Executive</option>
                                                <option value="ag">Attorney General</option>
                                            </form-select>
                                            <form-validation param="params.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Party" id="block_rep_lookup_party" name="block_rep_lookup_party" v-model="current_block.params.party">
                                                <option :value="null">All</option>
                                                <option value="democrat">Democrat</option>
                                                <option value="republican">Republican</option>
                                                <option value="independent">Independent</option>
                                            </form-select>
                                            <form-validation param="params.party" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                           <form-select label="Filter By" id="block_rep_lookup_filter_by" name="block_rep_lookup_filter_by" v-model="current_block.params.filter_by">
                                                <option :value="null">- Select Filter -</option>
                                                <option value="zip">ZIP</option>
                                                <option value="state">State</option>
                                                <option value="district">District</option>
                                            </form-select>
                                            <form-validation param="params.filter_by" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Value Type" id="block_rep_lookup_value_type" name="block_rep_lookup_value_type" v-model="current_block.params.value_type">
                                                <option :value="null">- Select Filter Value Type -</option>
                                                <option value="match_attribute">User Attribute Value</option>
                                                <option value="set_value">Set Value</option>
                                            </form-select>
                                            <form-validation param="params.value_type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.value_type === 'match_attribute'">
                                            <form-select label="Attribute" id="block_rep_lookup_attribute_id" name="block_rep_lookup_attribute_id" v-model="current_block.params.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in all_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="params.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.value_type === 'set_value'">
                                            <form-input label="Value" id="block_rep_lookup_value" name="block_rep_lookup_value" v-model="current_block.params.value" />
                                            <form-validation param="params.value" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <form-select label="Match Type" id="block_rep_lookup_match_by" name="block_rep_lookup_match_by" v-model="current_block.params.match_by">
                                                <option :value="null">- Select Match Type -</option>
                                                <option value="menu">Menu of Reps</option>
                                                <option value="first">First</option>
                                                <option value="first_answer">First to Answer</option>
                                                <option value="random">Random</option>
                                            </form-select>
                                            <form-validation param="params.match_by" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-label for="block_rep_lookup_include_reps" label="Include"></form-label>
                                            <div class="relative pb-3">
                                                <multiselect v-model="current_block.params.include_reps" :options="reps" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" :loading="reps_loading" @search-change="searchReps" track-by="phone" :internal-search="false" :options-limit="300" label="full_name" placeholder="Select Reps">
                                                </multiselect>
                                            </div>
                                            <form-validation param="params.include_reps" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-label for="exclude_reps" label="Exclude"></form-label>
                                            <div class="relative pb-3">
                                                <multiselect v-model="current_block.params.exclude_reps" :options="reps" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" :loading="reps_loading" @search-change="searchReps" track-by="phone" :internal-search="false" :options-limit="300" label="full_name" placeholder="Select Reps">
                                                </multiselect>
                                            </div>
                                            <form-validation param="params.exclude_reps" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <form-button @click="showRepSummaryDialog()">View Summary</form-button>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Hangup Block -->
                                <div v-if="current_block.messaging_type === 'hangup'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Hangup Block</div>
                                    </div>
                                    <div class="mt-2 mb-6">
                                        <p class="text-sm italic text-gray-500">End a call.</p>
                                    </div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>
                                </div>

                                <!-- Pause Block -->
                                <div v-if="current_block.messaging_type === 'pause'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Pause Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Pause a sequence during the call, or wait to start the call if at the beginning of a sequence.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Duration" name="block_pause_duration" id="block_pause_duration" type="number" min="1" max="10" v-model.number="current_block.params.duration" />
                                            <form-validation param="params.duration" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Tag Attribute Block -->
                                <div v-if="current_block.messaging_type === 'tag_attribute'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Tag Attribute Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Tag a caller with the associated attribute and value.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Attribute" id="block_tag_attribute_attribute_id" name="block_tag_attribute_attribute_id" v-model="current_block.params.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="params.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Value" id="block_tag_attribute_value" name="block_tag_attribute_value" v-model="current_block.params.value" />
                                            <form-validation param="params.value" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Conditional Block -->
                                <div v-if="current_block.messaging_type === 'conditional'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Conditional Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Check a set of criteria and perform an action based on result.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <h2>Conditions</h2>
                                    <div class="w-full">
                                        <!-- Row 1 -->
                                        <div class="flex flex-wrap mb-6 -mx-3">
                                            <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                                <form-select label="Join By" id="block_conditional_operator" name="block_conditional_operator" v-model="current_block.params.conditions.operator">
                                                    <option value="AND">AND</option>
                                                    <option value="OR">OR</option>
                                                </form-select>
                                                <form-validation param="params.conditions.operator" :errors="current_block.errors"></form-validation>
                                            </div>
                                        </div>

                                        <!-- Conditions -->
                                        <div class="flex flex-wrap mb-6 -mx-3" v-for="(condition, condition_index) in current_block.params.conditions.conditions" v-bind:key="condition_index">
                                            <form-validation :param="'params.conditions.conditions.'+condition_index" :errors="current_block.errors"></form-validation>

                                            <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0">
                                                <form-select label="Operator" :id="'block_conditional_'+condition_index+'_operator'" :name="'block_conditional_'+condition_index+'_operator'" v-model="condition.operator">
                                                    <optgroup label="Attribute">
                                                        <option value="=">Equal To</option>
                                                        <option value="!=">Not Equal To</option>
                                                        <option value="<">Less Than</option>
                                                        <option value="<=">Less Than Or Equal To</option>
                                                        <option value=">">Greater Than</option>
                                                        <option value=">=">Greater Than Or Equal To</option>
                                                        <option value="exists">Exists</option>
                                                        <option value="not_exists">Does Not Exist</option>
                                                    </optgroup>
                                                    <optgroup label="Segment">
                                                        <option value="on">On Segment</option>
                                                        <option value="not_on">Not On Segment</option>
                                                    </optgroup>
                                                </form-select>
                                                <form-validation :param="'params.conditions.conditions.'+condition_index+'.operator'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full px-3 mb-6 md:w-2/5 md:mb-0" v-if="condition.operator !== 'on' && condition.operator !== 'not_on'">
                                                <form-select label="Attribute" :id="'block_conditional_'+condition_index+'_attribute_id'" :name="'block_conditional_'+condition_index+'_attribute_id'" v-model="condition.attribute_id">
                                                    <option :value="null">- Select Attribute -</option>
                                                    <option v-for="attribute in all_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                </form-select>
                                                <form-validation :param="'params.conditions.conditions.'+condition_index+'.attribute_id'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full px-3 mb-6 md:w-3/5 md:mb-0" v-if="condition.operator === 'on' || condition.operator === 'not_on'">
                                                <form-select label="Segment" :id="'block_conditional_'+condition_index+'_segment_id'" :name="'block_conditional_'+condition_index+'_segment_id'" v-model="condition.segment_id">
                                                    <option :value="null">- Select Segment -</option>
                                                    <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                                                </form-select>
                                                <form-validation :param="'params.conditions.conditions.'+condition_index+'.segment_id'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="condition.operator !== 'on' && condition.operator !== 'not_on' && condition.operator !== 'exists' && condition.operator !== 'not_exists'">
                                                <form-input label="Value" :id="'block_conditional_'+condition_index+'_value'" :name="'block_conditional_'+condition_index+'_value'" v-model="condition.value" />
                                                <form-validation :param="'params.conditions.conditions.'+condition_index+'.value'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full h-full pt-6 pl-6 text-right cursor-pointer md:w-1/5">
                                                <form-button negative-action @click="removeCondition(condition_index)"><i class="fas fa-minus-circle"></i></form-button>
                                            </div>
                                        </div>

                                        <div class="flex flex-wrap mx-3 mb-6">
                                            <form-button positive-action @click="addCondition()"><i class="fas fa-plus-circle"></i> ADD</form-button>
                                        </div>

                                        <form-validation param="params.conditions.conditions" :errors="current_block.errors"></form-validation>
                                    </div>
                                </div>

                                <!-- Record Block -->
                                <div v-if="current_block.messaging_type === 'record'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Record Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Record audio from the caller.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Timeout" name="block_record_timeout" id="block_record_timeout" type="number" min="1" max="120" v-model.number="current_block.params.timeout" />
                                            <form-validation param="params.timeout" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Finish on Key" id="block_record_finish_on_key" name="block_record_finish_on_key" v-model="current_block.params.finish_on_key">
                                                <option :value="null">- Select Key -</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="0">0</option>
                                                <option value="*">*</option>
                                                <option value="#">#</option>
                                            </form-select>
                                            <form-validation param="params.finish_on_key" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Max Length (in seconds)" name="block_record_max_length" id="block_record_max_length" type="number" min="1" max="120" v-model.number="current_block.params.max_length" />
                                            <form-validation param="params.max_length" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Play Beep" id="block_record_play_beep" name="block_record_play_beep" v-model="current_block.params.play_beep">
                                                <option :value="null">- Select Beep Option -</option>
                                                <option value="true">Play</option>
                                                <option value="false">Do Not Play</option>
                                            </form-select>
                                            <form-validation param="params.play_beep" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Trim" id="block_record_trim" name="block_record_trim" v-model="current_block.params.trim">
                                                <option :value="null">- Select Trim Option -</option>
                                                <option value="trim-silence">Trim Silence</option>
                                                <option value="do-not-trim">Do Not Trim</option>
                                            </form-select>
                                            <form-validation param="params.trim" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="settings.talk.features.transcribe">
                                            <form-select label="Transcribe" id="block_record_transcribe" name="block_record_transcribe" v-model="current_block.params.transcribe">
                                                <option :value="null">- Select Transcription Option -</option>
                                                <option value="true">Transcribe to Text</option>
                                                <option value="false">Do Not Transcribe</option>
                                            </form-select>
                                            <form-validation param="params.transcribe" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Forward to Sequence Block -->
                                <div v-if="current_block.messaging_type === 'forward_to_sequence'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Forward to Sequence Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Forward the caller to another sequence.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Sequence" id="block_forward_to_sequence_sequence_id" name="block_forward_to_sequence_sequence_id" v-model="current_block.params.sequence_id">
                                                <option :value="null">- Select Sequence -</option>
                                                <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                            </form-select>
                                        <form-validation param="params.sequence_id" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- True Block -->
                                <div v-if="current_block.messaging_type === 'true'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Conditions Met</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Execute items if the conditions specified are met.</div>
                                </div>

                                <!-- False Block -->
                                <div v-if="current_block.messaging_type === 'false'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Conditions Not Met</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Execute items if the conditions specified are not met.</div>
                                </div>

                                <!-- Menu Block -->
                                <div v-if="current_block.messaging_type === 'menu'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Menu Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Provide a list of options the caller can select.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Choices" name="block_menu_choices" id="block_menu_choices" type="number" step="1" min="1" max="9" :modelValue="getBlocksLength()" @update:modelValue="choices => updateMenuChoices(choices)" />
                                        </form-field>

                                        <form-field>
                                            <form-select label="Attribute" id="block_menu_attribute_id" name="block_menu_attribute_id" v-model="current_block.params.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="params.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Timeout" name="block_menu_timeout" id="block_menu_timeout" type="number" v-model.number="current_block.params.timeout" />
                                            <form-validation param="params.timeout" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Language" id="block_menu_language" name="block_menu_language" v-model="current_block.params.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-CA">English (CA)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.language" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>

                                    <form-section title="Request Message" inline>
                                        <form-field wide>
                                            <form-select label="Request Type" id="block_menu_request_type" name="block_menu_request_type" v-model="current_block.params.request.type">
                                                <option value="speak">Speak</option>
                                                <option value="play">Play</option>
                                            </form-select>
                                            <form-validation param="params.request.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.request.type === 'speak'">
                                            <dynamic-textarea
                                                label="Request Message"
                                                name="block_menu_request"
                                                max="4096"
                                                v-model="current_block.params.request.message"
                                                :attributes="all_attributes">
                                            </dynamic-textarea>
                                            <form-validation param="params.request.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'speak'">
                                            <form-select id="block_menu_request_language" name="block_menu_request_language" label="Language" v-model="current_block.params.request.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.request.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'speak'">
                                            <form-select label="Voice" id="block_menu_request_voice" name="block_menu_request_voice" v-model="current_block.params.request.voice">
                                                <option :value="null">- Select Voice -</option>
                                                <optgroup label="Standard">
                                                    <option value="Man" v-if="current_block.params.request.language === 'en-US' || current_block.params.request.language === 'en-GB' || current_block.params.request.language === 'es-MX'">Man</option>
                                                    <option value="Woman" v-if="current_block.params.request.language === 'en-US' || current_block.params.request.language === 'en-GB' || current_block.params.request.language === 'es-MX'">Woman</option>
                                                </optgroup>
                                                <optgroup label="Premium">
                                                    <option value="Polly.Ivy" v-if="current_block.params.request.language === 'en-US'">Ivy</option>
                                                    <option value="Polly.Joanna" v-if="current_block.params.request.language === 'en-US'">Joanna</option>
                                                    <option value="Polly.Joey" v-if="current_block.params.request.language === 'en-US'">Joey</option>
                                                    <option value="Polly.Justin" v-if="current_block.params.request.language === 'en-US'">Justin</option>
                                                    <option value="Polly.Kendra" v-if="current_block.params.request.language === 'en-US'">Kendra</option>
                                                    <option value="Polly.Kimberly" v-if="current_block.params.request.language === 'en-US'">Kimberly</option>
                                                    <option value="Polly.Matthew" v-if="current_block.params.request.language === 'en-US'">Matthew</option>
                                                    <option value="Polly.Salli" v-if="current_block.params.request.language === 'en-US'">Salli</option>
                                                    <option value="Polly.Nicole" v-if="current_block.params.request.language === 'en-AU'">Nicole</option>
                                                    <option value="Polly.Russell" v-if="current_block.params.request.language === 'en-AU'">Russell</option>
                                                    <option value="Polly.Amy" v-if="current_block.params.request.language === 'en-GB'">Amy</option>
                                                    <option value="Polly.Brian" v-if="current_block.params.request.language === 'en-GB'">Brian</option>
                                                    <option value="Polly.Emma" v-if="current_block.params.request.language === 'en-GB'">Emma</option>
                                                    <option value="Polly.Mia" v-if="current_block.params.request.language === 'es-MX'">Mia</option>
                                                    <option value="Polly.Chantal" v-if="current_block.params.request.language === 'fr-CA'">Chantal</option>
                                                </optgroup>
                                            </form-select>
                                            <form-validation param="params.request.voice" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'play'">
                                            <form-select label="Audio Type" name="block_menu_type_audio" id="block_menu_type_audio" v-model="current_block.play_settings.request.audio_type" :disabled="current_block.params.request.attachment_key !== null">
                                                <option value="file">File Upload</option>
                                                <option value="record">Record Audio</option>
                                            </form-select>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'play'">
                                            <div v-if="current_block.params.request.attachment_key === null">
                                                <div v-if="current_block.play_settings.request.audio_type === 'file'">
                                                    <form-input label="Audio File" id="block_menu_request_file" name="block_menu_request_file" ref="request_file" @change="handleFileUpload" type="file" />
                                                    <form-validation param="params.request.attachment_key" :errors="current_block.errors"></form-validation>
                                                    <form-validation param="file" :errors="current_block.errors"></form-validation>

                                                    <div class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="submitFile('request')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                                <div v-if="current_block.play_settings.request.audio_type === 'record'">
                                                    <form-label label="Record Audio" for="block_menu_request_record"></form-label>
                                                    <div class="flex mx-3 mb-6 flex-nowrap">
                                                        <div class="w-10 h-10 mt-2 mr-2">
                                                            <button id="block_menu_request_record" v-bind:class="{'bg-green-500 hover:bg-green-800': (current_block.play_settings.request.status === 'waiting'), 'bg-red-500 hover:bg-red-800': (current_block.play_settings.request.status === 'recording')}" class="inline-flex items-center justify-center w-10 h-10 text-white transition-colors duration-150 rounded-full focus:shadow-outline" type="button" @click="startStopRecording('request')">
                                                                <i v-if="current_block.play_settings.request.status === 'waiting'" class="fas fa-microphone"></i>
                                                                <i v-if="current_block.play_settings.request.status === 'recording'" class="far fa-stop-circle"></i>
                                                            </button>
                                                        </div>

                                                        <div class="w-full mt-2">
                                                            <audio v-if="current_block.play_settings.request.audio_url" :src="current_block.play_settings.request.audio_url" controls class="w-full" />
                                                        </div>
                                                    </div>

                                                    <div v-if="current_block.play_settings.request.audio_url" class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="saveFile('request', 'request')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <audio v-if="current_block.params.request.attachment_key !== null" controls class="pb-2">
                                                    <source :src="'/attachments/'+current_block.params.request.attachment_key" :type="current_block.params.request.attachment_mimetype" />
                                                </audio>

                                                <div class="flex flex-wrap mx-3 mb-6 text-right">
                                                    <form-button negative-action @click="removeFile('request')"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                                                </div>
                                            </div>
                                        </form-field>
                                    </form-section>

                                    <form-section title="Invalid Message" inline>
                                        <form-field wide>
                                            <form-select label="Invalid Type" id="block_menu_invalid_type" name="block_menu_invalid_type" v-model="current_block.params.invalid.type">
                                                <option value="speak">Speak</option>
                                                <option value="play">Play</option>
                                            </form-select>
                                            <form-validation param="params.invalid.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.invalid.type === 'speak'">
                                            <dynamic-textarea
                                                label="Invalid Message"
                                                name="block_menu_invalid"
                                                max="4096"
                                                v-model="current_block.params.invalid.message"
                                                :attributes="all_attributes">
                                            </dynamic-textarea>
                                            <form-validation param="params.invalid.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'speak'">
                                            <form-select id="block_menu_invalid_language" name="block_menu_invalid_language" label="Language" v-model="current_block.params.invalid.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.invalid.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'speak'">
                                            <form-select label="Voice" id="block_menu_invalid_voice" name="block_menu_invalid_voice" v-model="current_block.params.invalid.voice">
                                                <option :value="null">- Select Voice -</option>
                                                <optgroup label="Standard">
                                                    <option value="Man" v-if="current_block.params.invalid.language === 'en-US' || current_block.params.invalid.language === 'en-GB' || current_block.params.invalid.language === 'es-MX'">Man</option>
                                                    <option value="Woman" v-if="current_block.params.invalid.language === 'en-US' || current_block.params.invalid.language === 'en-GB' || current_block.params.invalid.language === 'es-MX'">Woman</option>
                                                </optgroup>
                                                <optgroup label="Premium">
                                                    <option value="Polly.Ivy" v-if="current_block.params.invalid.language === 'en-US'">Ivy</option>
                                                    <option value="Polly.Joanna" v-if="current_block.params.invalid.language === 'en-US'">Joanna</option>
                                                    <option value="Polly.Joey" v-if="current_block.params.invalid.language === 'en-US'">Joey</option>
                                                    <option value="Polly.Justin" v-if="current_block.params.invalid.language === 'en-US'">Justin</option>
                                                    <option value="Polly.Kendra" v-if="current_block.params.invalid.language === 'en-US'">Kendra</option>
                                                    <option value="Polly.Kimberly" v-if="current_block.params.invalid.language === 'en-US'">Kimberly</option>
                                                    <option value="Polly.Matthew" v-if="current_block.params.invalid.language === 'en-US'">Matthew</option>
                                                    <option value="Polly.Salli" v-if="current_block.params.invalid.language === 'en-US'">Salli</option>
                                                    <option value="Polly.Nicole" v-if="current_block.params.invalid.language === 'en-AU'">Nicole</option>
                                                    <option value="Polly.Russell" v-if="current_block.params.invalid.language === 'en-AU'">Russell</option>
                                                    <option value="Polly.Amy" v-if="current_block.params.invalid.language === 'en-GB'">Amy</option>
                                                    <option value="Polly.Brian" v-if="current_block.params.invalid.language === 'en-GB'">Brian</option>
                                                    <option value="Polly.Emma" v-if="current_block.params.invalid.language === 'en-GB'">Emma</option>
                                                    <option value="Polly.Mia" v-if="current_block.params.invalid.language === 'es-MX'">Mia</option>
                                                    <option value="Polly.Chantal" v-if="current_block.params.invalid.language === 'fr-CA'">Chantal</option>
                                                </optgroup>
                                            </form-select>
                                            <form-validation param="params.invalid.voice" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'play'">
                                            <form-select label="Audio Type" name="block_menu_type_audio" id="block_menu_type_audio" v-model="current_block.play_settings.invalid.audio_type" :disabled="current_block.params.invalid.attachment_key !== null">
                                                <option value="file">File Upload</option>
                                                <option value="record">Record Audio</option>
                                            </form-select>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'play'">
                                            <div v-if="current_block.params.invalid.attachment_key === null">
                                                <div v-if="current_block.play_settings.invalid.audio_type === 'file'">
                                                    <form-input label="Audio File" id="block_menu_invalid_file" name="block_menu_invalid_file" ref="invalid_file" @change="handleFileUpload" type="file" />
                                                    <form-validation param="params.invalid.attachment_key" :errors="current_block.errors"></form-validation>
                                                    <form-validation param="file" :errors="current_block.errors"></form-validation>

                                                    <div class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="submitFile('invalid')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                                <div v-if="current_block.play_settings.invalid.audio_type === 'record'">
                                                    <form-label label="Record Audio" for="block_menu_invalid_record"></form-label>
                                                    <div class="flex mx-3 mb-6 flex-nowrap">
                                                        <div class="w-10 h-10 mt-2 mr-2">
                                                            <button id="block_menu_invalid_record" v-bind:class="{'bg-green-500 hover:bg-green-800': (current_block.play_settings.invalid.status === 'waiting'), 'bg-red-500 hover:bg-red-800': (current_block.play_settings.invalid.status === 'recording')}" class="inline-flex items-center justify-center w-10 h-10 text-white transition-colors duration-150 rounded-full focus:shadow-outline" type="button" @click="startStopRecording('invalid')">
                                                                <i v-if="current_block.play_settings.invalid.status === 'waiting'" class="fas fa-microphone"></i>
                                                                <i v-if="current_block.play_settings.invalid.status === 'recording'" class="far fa-stop-circle"></i>
                                                            </button>
                                                        </div>

                                                        <div class="w-full mt-2">
                                                            <audio v-if="current_block.play_settings.invalid.audio_url" :src="current_block.play_settings.invalid.audio_url" controls class="w-full" />
                                                        </div>
                                                    </div>

                                                    <div v-if="current_block.play_settings.invalid.audio_url" class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button negative-action @click="saveFile('invalid', 'invalid')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <audio v-if="current_block.params.invalid.attachment_key !== null" controls class="pb-2">
                                                    <source :src="'/attachments/'+current_block.params.invalid.attachment_key" :type="current_block.params.invalid.attachment_mimetype" />
                                                </audio>

                                                <div class="flex flex-wrap mx-3 mb-6 text-right">
                                                    <form-button negative-action @click="removeFile('invalid')"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                                                </div>
                                            </div>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Menu Item Block -->
                                <div v-if="current_block.messaging_type === 'menu_item'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Menu Item Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">View details for a specific menu item.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Key" id="block_menu_item_key" name="block_menu_item_key" v-model="current_block.params.key">
                                                <option :value="null">- Select Key -</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                            </form-select>
                                            <form-validation param="params.key" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Name" id="block_menu_item_name" name="block_menu_item_name" v-model="current_block.params.name" />
                                            <form-validation param="params.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Attribute Value" id="block_menu_item_attribute_value" name="block_menu_item_attribute_value" v-model="current_block.params.attribute_value" />
                                            <form-validation param="params.attribute_value" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Random Block -->
                                <div v-if="current_block.messaging_type === 'random'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Random Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Provide a list of random options for the user.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Choices" name="block_menu_choices" id="block_menu_choices" type="number" step="1" min="1" max="9" :modelValue="getBlocksLength()" @update:modelValue="choices => updateRandomChoices(choices)" />
                                        </form-field>

                                        <form-field>
                                            <form-select label="Attribute" id="block_menu_attribute_id" name="block_menu_attribute_id" v-model="current_block.params.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="params.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Random Item Block -->
                                <div v-if="current_block.messaging_type === 'random_item'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Random Item Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">View details for a specific random item.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Name" class="form-text" id="block_random_item_name" name="block_random_item_name" v-model="current_block.params.name" />
                                            <form-validation param="params.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Percentage" id="block_random_item_percent" name="block_random_item_percent" v-model.number="current_block.params.percent" />
                                            <form-validation param="params.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Attribute Value" id="block_random_item_attribute_value" name="block_random_item_attribute_value" v-model="current_block.params.attribute_value" />
                                            <form-validation param="params.attribute_value" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Poll Block -->
                                <div v-if="current_block.messaging_type === 'poll'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Poll Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Provide a list of options the caller can select.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Choices" name="block_poll_choices" id="block_poll_choices" type="number" step="1" min="1" max="9" :modelValue="getBlocksLength()" @update:modelValue="choices => updatePollChoices(choices)" />
                                        </form-field>

                                        <form-field>
                                            <form-select label="Attribute" id="block_poll_attribute_id" name="block_poll_attribute_id" v-model="current_block.params.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="params.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Timeout" name="block_poll_timeout" id="block_poll_timeout" type="number" v-model.number="current_block.params.timeout" />
                                            <form-validation param="params.timeout" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Language" id="block_poll_language" name="block_poll_language" v-model="current_block.params.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-CA">English (CA)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Voting Type" id="block_poll_voting_type" name="block_poll_voting_type" v-model="current_block.params.voting_type">
                                                <option :value="null">- Select Voting Type -</option>
                                                <option value="once">Once Only</option>
                                                <option value="first_only">First Time Only</option>
                                                <option value="last_only">Latest Time Only</option>
                                                <option value="multiple">Multiple Times</option>
                                            </form-select>
                                            <form-validation param="params.voting_type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Allow External Access" id="block_poll_allow_external_access" name="block_poll_allow_external_access" v-model="current_block.params.allow_external_access">
                                                <option :value="null">- Select External Access Option -</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </form-select>
                                            <form-validation param="params.allow_external_access" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Send Results" id="block_poll_send_results" name="block_poll_send_results" v-model="current_block.params.send_results">
                                                <option :value="null">- Select Send Results Option -</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </form-select>
                                            <form-validation param="params.send_results" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>

                                    <form-section title="Request Message" inline>
                                        <form-field wide>
                                            <form-select label="Request Type" id="block_poll_request_type" name="block_poll_request_type" v-model="current_block.params.request.type">
                                                <option value="speak">Speak</option>
                                                <option value="play">Play</option>
                                            </form-select>
                                            <form-validation param="params.request.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.request.type === 'speak'">
                                            <dynamic-textarea
                                                label="Request Message"
                                                name="block_poll_request"
                                                max="4096"
                                                v-model="current_block.params.request.message"
                                                :attributes="all_attributes">
                                            </dynamic-textarea>
                                            <form-validation param="params.request.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'speak'">
                                            <form-select id="block_poll_request_language" name="block_poll_request_language" label="Language" v-model="current_block.params.request.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.request.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'speak'">
                                            <form-select label="Voice" id="block_poll_request_voice" name="block_poll_request_voice" v-model="current_block.params.request.voice">
                                                <option :value="null">- Select Voice -</option>
                                                <optgroup label="Standard">
                                                    <option value="Man" v-if="current_block.params.request.language === 'en-US' || current_block.params.request.language === 'en-GB' || current_block.params.request.language === 'es-MX'">Man</option>
                                                    <option value="Woman" v-if="current_block.params.request.language === 'en-US' || current_block.params.request.language === 'en-GB' || current_block.params.request.language === 'es-MX'">Woman</option>
                                                </optgroup>
                                                <optgroup label="Premium">
                                                    <option value="Polly.Ivy" v-if="current_block.params.request.language === 'en-US'">Ivy</option>
                                                    <option value="Polly.Joanna" v-if="current_block.params.request.language === 'en-US'">Joanna</option>
                                                    <option value="Polly.Joey" v-if="current_block.params.request.language === 'en-US'">Joey</option>
                                                    <option value="Polly.Justin" v-if="current_block.params.request.language === 'en-US'">Justin</option>
                                                    <option value="Polly.Kendra" v-if="current_block.params.request.language === 'en-US'">Kendra</option>
                                                    <option value="Polly.Kimberly" v-if="current_block.params.request.language === 'en-US'">Kimberly</option>
                                                    <option value="Polly.Matthew" v-if="current_block.params.request.language === 'en-US'">Matthew</option>
                                                    <option value="Polly.Salli" v-if="current_block.params.request.language === 'en-US'">Salli</option>
                                                    <option value="Polly.Nicole" v-if="current_block.params.request.language === 'en-AU'">Nicole</option>
                                                    <option value="Polly.Russell" v-if="current_block.params.request.language === 'en-AU'">Russell</option>
                                                    <option value="Polly.Amy" v-if="current_block.params.request.language === 'en-GB'">Amy</option>
                                                    <option value="Polly.Brian" v-if="current_block.params.request.language === 'en-GB'">Brian</option>
                                                    <option value="Polly.Emma" v-if="current_block.params.request.language === 'en-GB'">Emma</option>
                                                    <option value="Polly.Mia" v-if="current_block.params.request.language === 'es-MX'">Mia</option>
                                                    <option value="Polly.Chantal" v-if="current_block.params.request.language === 'fr-CA'">Chantal</option>
                                                </optgroup>
                                            </form-select>
                                            <form-validation param="params.request.voice" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'play'">
                                            <form-select label="Audio Type" name="block_poll_type_audio" id="block_poll_type_audio" v-model="current_block.play_settings.request.audio_type" :disabled="current_block.params.request.attachment_key !== null">
                                                <option value="file">File Upload</option>
                                                <option value="record">Record Audio</option>
                                            </form-select>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'play'">
                                            <div v-if="current_block.params.request.attachment_key === null">
                                                <div v-if="current_block.play_settings.request.audio_type === 'file'">
                                                    <form-input label="Audio File" id="block_poll_request_file" name="block_poll_request_file" ref="request_file" @change="handleFileUpload" type="file" />
                                                    <form-validation param="params.request.attachment_key" :errors="current_block.errors"></form-validation>
                                                    <form-validation param="file" :errors="current_block.errors"></form-validation>

                                                    <div class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="submitFile('request')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                                <div v-if="current_block.play_settings.request.audio_type === 'record'">
                                                    <form-label label="Record Audio" for="block_poll_request_record">Record Audio</form-label>
                                                    <div class="flex mx-3 mb-6 flex-nowrap">
                                                        <div class="w-10 h-10 mt-2 mr-2">
                                                            <button id="block_poll_request_record" v-bind:class="{'bg-green-500 hover:bg-green-800': (current_block.play_settings.request.status === 'waiting'), 'bg-red-500 hover:bg-red-800': (current_block.play_settings.request.status === 'recording')}" class="inline-flex items-center justify-center w-10 h-10 text-white transition-colors duration-150 rounded-full focus:shadow-outline" type="button" @click="startStopRecording('request')">
                                                                <i v-if="current_block.play_settings.request.status === 'waiting'" class="fas fa-microphone"></i>
                                                                <i v-if="current_block.play_settings.request.status === 'recording'" class="far fa-stop-circle"></i>
                                                            </button>
                                                        </div>

                                                        <div class="w-full mt-2">
                                                            <audio v-if="current_block.play_settings.request.audio_url" :src="current_block.play_settings.request.audio_url" controls class="w-full" />
                                                        </div>
                                                    </div>

                                                    <div v-if="current_block.play_settings.request.audio_url" class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button negative-action @click="saveFile('request', 'request')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <audio v-if="current_block.params.request.attachment_key !== null" controls class="pb-2">
                                                    <source :src="'/attachments/'+current_block.params.request.attachment_key" :type="current_block.params.request.attachment_mimetype" />
                                                </audio>

                                                <div class="flex flex-wrap mx-3 mb-6 text-right">
                                                    <form-button @click="removeFile('request')"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                                                </div>
                                            </div>
                                        </form-field>
                                    </form-section>

                                    <form-section title="Invalid Message" inline>
                                        <form-field wide>
                                            <form-select label="Invalid Type" id="block_poll_invalid_type" name="block_poll_invalid_type" v-model="current_block.params.invalid.type">
                                                <option value="speak">Speak</option>
                                                <option value="play">Play</option>
                                                <option value="none">None</option>
                                            </form-select>
                                            <form-validation param="params.invalid.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.invalid.type === 'speak'">
                                            <dynamic-textarea
                                                label="Invalid Response Message"
                                                name="block_poll_invalid"
                                                max="4096"
                                                v-if="current_block.params.invalid.type === 'speak'"
                                                v-model="current_block.params.invalid.message"
                                                :attributes="all_attributes">
                                            </dynamic-textarea>
                                            <form-validation param="params.invalid.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'speak'">
                                            <form-select id="block_poll_invalid_language" name="block_poll_invalid_language" label="Language" v-model="current_block.params.invalid.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.invalid.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'speak'">
                                            <form-select label="Voice" id="block_poll_invalid_voice" name="block_poll_invalid_voice" v-model="current_block.params.invalid.voice">
                                                <option :value="null">- Select Voice -</option>
                                                <optgroup label="Standard">
                                                    <option value="Man" v-if="current_block.params.invalid.language === 'en-US' || current_block.params.invalid.language === 'en-GB' || current_block.params.invalid.language === 'es-MX'">Man</option>
                                                    <option value="Woman" v-if="current_block.params.invalid.language === 'en-US' || current_block.params.invalid.language === 'en-GB' || current_block.params.invalid.language === 'es-MX'">Woman</option>
                                                </optgroup>
                                                <optgroup label="Premium">
                                                    <option value="Polly.Ivy" v-if="current_block.params.invalid.language === 'en-US'">Ivy</option>
                                                    <option value="Polly.Joanna" v-if="current_block.params.invalid.language === 'en-US'">Joanna</option>
                                                    <option value="Polly.Joey" v-if="current_block.params.invalid.language === 'en-US'">Joey</option>
                                                    <option value="Polly.Justin" v-if="current_block.params.invalid.language === 'en-US'">Justin</option>
                                                    <option value="Polly.Kendra" v-if="current_block.params.invalid.language === 'en-US'">Kendra</option>
                                                    <option value="Polly.Kimberly" v-if="current_block.params.invalid.language === 'en-US'">Kimberly</option>
                                                    <option value="Polly.Matthew" v-if="current_block.params.invalid.language === 'en-US'">Matthew</option>
                                                    <option value="Polly.Salli" v-if="current_block.params.invalid.language === 'en-US'">Salli</option>
                                                    <option value="Polly.Nicole" v-if="current_block.params.invalid.language === 'en-AU'">Nicole</option>
                                                    <option value="Polly.Russell" v-if="current_block.params.invalid.language === 'en-AU'">Russell</option>
                                                    <option value="Polly.Amy" v-if="current_block.params.invalid.language === 'en-GB'">Amy</option>
                                                    <option value="Polly.Brian" v-if="current_block.params.invalid.language === 'en-GB'">Brian</option>
                                                    <option value="Polly.Emma" v-if="current_block.params.invalid.language === 'en-GB'">Emma</option>
                                                    <option value="Polly.Mia" v-if="current_block.params.invalid.language === 'es-MX'">Mia</option>
                                                    <option value="Polly.Chantal" v-if="current_block.params.invalid.language === 'fr-CA'">Chantal</option>
                                                </optgroup>
                                            </form-select>
                                            <form-validation param="params.invalid.voice" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'play'">
                                            <form-select label="Audio Type" name="block_poll_audio_type" id="block_poll_audio_type" v-model="current_block.play_settings.invalid.audio_type" :disabled="current_block.params.invalid.attachment_key !== null">
                                                <option value="file">File Upload</option>
                                                <option value="record">Record Audio</option>
                                            </form-select>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'play'">
                                            <div v-if="current_block.params.invalid.attachment_key === null">
                                                <div v-if="current_block.play_settings.invalid.audio_type === 'file'">
                                                    <form-input label="Audio File" id="block_poll_invalid_audio_file" name="block_poll_invalid_audio_file" ref="invalid_file" @change="handleFileUpload" type="file" />
                                                    <form-validation param="params.invalid.attachment_key" :errors="current_block.errors"></form-validation>
                                                    <form-validation param="file" :errors="current_block.errors"></form-validation>

                                                    <div class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="submitFile('invalid')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                                <div v-if="current_block.play_settings.invalid.audio_type === 'record'">
                                                    <form-label label="Record Audio" for="block_poll_invalid_record">Record Audio</form-label>
                                                    <div class="flex mx-3 mb-6 flex-nowrap">
                                                        <div class="w-10 h-10 mt-2 mr-2">
                                                            <button id="block_poll_invalid_record" v-bind:class="{'bg-green-500 hover:bg-green-800': (current_block.play_settings.invalid.status === 'waiting'), 'bg-red-500 hover:bg-red-800': (current_block.play_settings.invalid.status === 'recording')}" class="inline-flex items-center justify-center w-10 h-10 text-white transition-colors duration-150 rounded-full focus:shadow-outline" type="button" @click="startStopRecording('invalid')">
                                                                <i v-if="current_block.play_settings.invalid.status === 'waiting'" class="fas fa-microphone"></i>
                                                                <i v-if="current_block.play_settings.invalid.status === 'recording'" class="far fa-stop-circle"></i>
                                                            </button>
                                                        </div>

                                                        <div class="w-full mt-2">
                                                            <audio v-if="current_block.play_settings.invalid.audio_url" :src="current_block.play_settings.invalid.audio_url" controls class="w-full" />
                                                        </div>
                                                    </div>

                                                    <div v-if="current_block.play_settings.invalid.audio_url" class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button negative-action @click="saveFile('invalid', 'invalid')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <audio v-if="current_block.params.invalid.attachment_key !== null" controls class="pb-2">
                                                    <source :src="'/attachments/'+current_block.params.invalid.attachment_key" :type="current_block.params.invalid.attachment_mimetype" />
                                                </audio>

                                                <div class="flex flex-wrap mx-3 mb-6 text-right">
                                                    <form-button negative-action @click="removeFile('invalid')"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                                                </div>
                                            </div>
                                        </form-field>
                                    </form-section>

                                    <form-section title="Already Voted Message" inline v-if="current_block.params.voting_type === 'once'">
                                        <form-field wide>
                                            <form-select label="Already Voted Type" id="block_poll_voted_type" name="block_poll_voted_type" v-model="current_block.params.voted.type">
                                                <option value="speak">Speak</option>
                                                <option value="play">Play</option>
                                                <option value="none">None</option>
                                            </form-select>
                                            <form-validation param="params.voted.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.voted.type === 'speak'">
                                            <dynamic-textarea
                                                label="Already Voted Response Message"
                                                name="block_poll_voted"
                                                max="4096"
                                                v-if="current_block.params.voted.type === 'speak'"
                                                v-model="current_block.params.voted.message"
                                                :attributes="all_attributes">
                                            </dynamic-textarea>
                                            <form-validation param="params.voted.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.voted.type === 'speak'">
                                            <form-select id="block_poll_voted_language" name="block_poll_voted_language" label="Language" v-model="current_block.params.voted.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.voted.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.voted.type === 'speak'">
                                            <form-select label="Voice" id="block_poll_voted_voice" name="block_poll_voted_voice" v-model="current_block.params.voted.voice">
                                                <option :value="null">- Select Voice -</option>
                                                <optgroup label="Standard">
                                                    <option value="Man" v-if="current_block.params.voted.language === 'en-US' || current_block.params.voted.language === 'en-GB' || current_block.params.voted.language === 'es-MX'">Man</option>
                                                    <option value="Woman" v-if="current_block.params.voted.language === 'en-US' || current_block.params.voted.language === 'en-GB' || current_block.params.voted.language === 'es-MX'">Woman</option>
                                                </optgroup>
                                                <optgroup label="Premium">
                                                    <option value="Polly.Ivy" v-if="current_block.params.voted.language === 'en-US'">Ivy</option>
                                                    <option value="Polly.Joanna" v-if="current_block.params.voted.language === 'en-US'">Joanna</option>
                                                    <option value="Polly.Joey" v-if="current_block.params.voted.language === 'en-US'">Joey</option>
                                                    <option value="Polly.Justin" v-if="current_block.params.voted.language === 'en-US'">Justin</option>
                                                    <option value="Polly.Kendra" v-if="current_block.params.voted.language === 'en-US'">Kendra</option>
                                                    <option value="Polly.Kimberly" v-if="current_block.params.voted.language === 'en-US'">Kimberly</option>
                                                    <option value="Polly.Matthew" v-if="current_block.params.voted.language === 'en-US'">Matthew</option>
                                                    <option value="Polly.Salli" v-if="current_block.params.voted.language === 'en-US'">Salli</option>
                                                    <option value="Polly.Nicole" v-if="current_block.params.voted.language === 'en-AU'">Nicole</option>
                                                    <option value="Polly.Russell" v-if="current_block.params.voted.language === 'en-AU'">Russell</option>
                                                    <option value="Polly.Amy" v-if="current_block.params.voted.language === 'en-GB'">Amy</option>
                                                    <option value="Polly.Brian" v-if="current_block.params.voted.language === 'en-GB'">Brian</option>
                                                    <option value="Polly.Emma" v-if="current_block.params.voted.language === 'en-GB'">Emma</option>
                                                    <option value="Polly.Mia" v-if="current_block.params.voted.language === 'es-MX'">Mia</option>
                                                    <option value="Polly.Chantal" v-if="current_block.params.voted.language === 'fr-CA'">Chantal</option>
                                                </optgroup>
                                            </form-select>
                                            <form-validation param="params.voted.voice" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.voted.type === 'play'">
                                            <form-select label="Audio Type" name="block_poll_audio_type" id="block_poll_audio_type" v-model="current_block.play_settings.voted.audio_type" :disabled="current_block.params.voted.attachment_key !== null">
                                                <option value="file">File Upload</option>
                                                <option value="record">Record Audio</option>
                                            </form-select>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.voted.type === 'play'">
                                            <div v-if="current_block.params.voted.attachment_key === null">
                                                <div v-if="current_block.play_settings.voted.audio_type === 'file'">
                                                    <label class="form-label" for="block_poll_voted_audio_file">Audio File</label>
                                                    <input class="form-text" id="block_poll_voted_audio_file" name="block_poll_voted_audio_file" ref="voted_file" @change="handleFileUpload" type="file" />
                                                    <form-validation param="params.voted.attachment_key" :errors="current_block.errors"></form-validation>
                                                    <form-validation param="file" :errors="current_block.errors"></form-validation>

                                                    <div class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="submitFile('voted')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                                <div v-if="current_block.play_settings.voted.audio_type === 'record'">
                                                    <label class="form-label" for="block_poll_voted_record">Record Audio</label>
                                                    <div class="flex mx-3 mb-6 flex-nowrap">
                                                        <div class="w-10 h-10 mt-2 mr-2">
                                                            <button id="block_poll_voted_record" v-bind:class="{'bg-green-500 hover:bg-green-800': (current_block.play_settings.voted.status === 'waiting'), 'bg-red-500 hover:bg-red-800': (current_block.play_settings.voted.status === 'recording')}" class="inline-flex items-center justify-center w-10 h-10 text-white transition-colors duration-150 rounded-full focus:shadow-outline" type="button" @click="startStopRecording('voted')">
                                                                <i v-if="current_block.play_settings.voted.status === 'waiting'" class="fas fa-microphone"></i>
                                                                <i v-if="current_block.play_settings.voted.status === 'recording'" class="far fa-stop-circle"></i>
                                                            </button>
                                                        </div>

                                                        <div class="w-full mt-2">
                                                            <audio v-if="current_block.play_settings.voted.audio_url" :src="current_block.play_settings.voted.audio_url" controls class="w-full" />
                                                        </div>
                                                    </div>

                                                    <div v-if="current_block.play_settings.voted.audio_url" class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="saveFile('voted', 'voted')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <audio v-if="current_block.params.voted.attachment_key !== null" controls class="pb-2">
                                                    <source :src="'/attachments/'+current_block.params.voted.attachment_key" :type="current_block.params.voted.attachment_mimetype" />
                                                </audio>

                                                <div class="flex flex-wrap mx-3 mb-6 text-right">
                                                    <form-button negative-action @click="removeFile('voted')"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                                                </div>
                                            </div>
                                        </form-field>
                                    </form-section>

                                    <form-section title="Results Message" inline v-if="current_block.params.send_results === 'true'">
                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Results Message"
                                                name="block_poll_results"
                                                max="4096"
                                                showPollOptions
                                                v-model="current_block.params.results.message"
                                                :attributes="all_attributes">
                                            </dynamic-textarea>
                                            <form-validation param="params.results.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.results.type === 'speak'">
                                            <form-select id="block_poll_results_language" name="block_poll_results_language" label="Language" v-model="current_block.params.results.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.results.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Voice" id="block_poll_results_voice" name="block_poll_results_voice" v-model="current_block.params.results.voice">
                                                <option :value="null">- Select Voice -</option>
                                                <optgroup label="Standard">
                                                    <option value="Man" v-if="current_block.params.results.language === 'en-US' || current_block.params.results.language === 'en-GB' || current_block.params.results.language === 'es-MX'">Man</option>
                                                    <option value="Woman" v-if="current_block.params.results.language === 'en-US' || current_block.params.results.language === 'en-GB' || current_block.params.results.language === 'es-MX'">Woman</option>
                                                </optgroup>
                                                <optgroup label="Premium">
                                                    <option value="Polly.Ivy" v-if="current_block.params.results.language === 'en-US'">Ivy</option>
                                                    <option value="Polly.Joanna" v-if="current_block.params.results.language === 'en-US'">Joanna</option>
                                                    <option value="Polly.Joey" v-if="current_block.params.results.language === 'en-US'">Joey</option>
                                                    <option value="Polly.Justin" v-if="current_block.params.results.language === 'en-US'">Justin</option>
                                                    <option value="Polly.Kendra" v-if="current_block.params.results.language === 'en-US'">Kendra</option>
                                                    <option value="Polly.Kimberly" v-if="current_block.params.results.language === 'en-US'">Kimberly</option>
                                                    <option value="Polly.Matthew" v-if="current_block.params.results.language === 'en-US'">Matthew</option>
                                                    <option value="Polly.Salli" v-if="current_block.params.results.language === 'en-US'">Salli</option>
                                                    <option value="Polly.Nicole" v-if="current_block.params.results.language === 'en-AU'">Nicole</option>
                                                    <option value="Polly.Russell" v-if="current_block.params.results.language === 'en-AU'">Russell</option>
                                                    <option value="Polly.Amy" v-if="current_block.params.results.language === 'en-GB'">Amy</option>
                                                    <option value="Polly.Brian" v-if="current_block.params.results.language === 'en-GB'">Brian</option>
                                                    <option value="Polly.Emma" v-if="current_block.params.results.language === 'en-GB'">Emma</option>
                                                    <option value="Polly.Mia" v-if="current_block.params.results.language === 'es-MX'">Mia</option>
                                                    <option value="Polly.Chantal" v-if="current_block.params.results.language === 'fr-CA'">Chantal</option>
                                                </optgroup>
                                            </form-select>
                                            <form-validation param="params.results.voice" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Poll Item Block -->
                                <div v-if="current_block.messaging_type === 'poll_item'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Poll Item Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">View details for a specific poll item.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Key" id="block_poll_item_key" name="block_poll_item_key" v-model="current_block.params.key">
                                                <option :value="null">- Select Key -</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                            </form-select>
                                            <form-validation param="params.key" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Name" id="block_poll_item_name" name="block_poll_item_name" v-model="current_block.params.name" />
                                            <form-validation param="params.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Attribute Value" id="block_poll_item_block_poll_item_attribute_value" name="block_poll_item_block_poll_item_attribute_value" v-model="current_block.params.attribute_value" />
                                            <form-validation param="params.attribute_value" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- ABSplit Block -->
                                <div v-if="current_block.messaging_type === 'absplit'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">A/B Split Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Split responses to users using A/B split testing.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Choices" name="block_absplit_choices" id="block_absplit_choices" type="number" step="1" min="1" max="9" :modelValue="getBlocksLength()" @update:modelValue="choices => updateABSplitChoices(choices)" />
                                        </form-field>

                                        <form-field>
                                            <form-select label="Attribute" id="block_absplit_attribute_id" name="block_absplit_attribute_id" v-model="current_block.params.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="params.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Split Type" id="block_absplit_split_type" name="block_absplit_split_type" v-model="current_block.params.split_type">
                                                <option :value="null">- Select Split Type -</option>
                                                <option value="first_only">First Time Only</option>
                                                <option value="last_only">Latest Time Only</option>
                                            </form-select>
                                            <form-validation param="params.split_type" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- ABSplit Item Block -->
                                <div v-if="current_block.messaging_type === 'absplit_item'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">A/B Split Item Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">View details for a specific A/B split item.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Name" id="block_absplit_item_name" name="block_absplit_item_name" v-model="current_block.params.name" />
                                            <form-validation param="params.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Percentage" id="block_absplit_item_percent" name="block_absplit_item_percent" v-model.number="current_block.params.percent" />
                                            <form-validation param="params.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Attribute Value" id="block_absplit_item_attribute_value" name="block_absplit_item_attribute_value" v-model="current_block.params.attribute_value" />
                                            <form-validation param="params.attribute_value" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Collect Attribute Block -->
                                <div v-if="current_block.messaging_type === 'collect_attribute'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Collect Attribute Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Request a response to store as an attribute field on the caller's profile.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Attribute" class="form-select" id="block_collect_attribute_attribute_id" name="block_collect_attribute_attribute_id" v-model="current_block.params.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="params.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Input Type" id="block_collect_attribute_input_type" name="block_collect_attribute_input_type" v-model="current_block.params.input_type">
                                                    <option :value="null">- Select Input Type -</option>
                                                    <option value="dtmf">Dual Tone</option>
                                                    <option value="speech" v-if="settings.talk.features.speech_to_text">Speech</option>
                                                    <option value="dtmf speech" v-if="settings.talk.features.speech_to_text">Both</option>
                                            </form-select>
                                            <form-validation param="params.input_type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Finish on Key" id="block_collect_attribute_finish_on_key" name="block_collect_attribute_finish_on_key" v-model="current_block.params.finish_on_key">
                                                <option :value="null">- Select Key -</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="0">0</option>
                                                <option value="*">*</option>
                                                <option value="#">#</option>
                                            </form-select>
                                            <form-validation param="params.finish_on_key" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Timeout" name="block_collect_attribute_timeout" id="block_collect_attribute_timeout" type="number" v-model.number="current_block.params.timeout" />
                                            <form-validation param="params.timeout" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Profanity Filter" id="block_collect_attribute_profanity_filter" name="block_collect_attribute_profanity_filter" v-model="current_block.params.profanity_filter">
                                                <option :value="null">- Select Profanity Filter Option -</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </form-select>
                                            <form-validation param="params.profanity_filter" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Language" id="block_collect_attribute_language" name="block_collect_attribute_language" v-model="current_block.params.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-CA">English (CA)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.language" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>

                                    <form-section title="Request Message" inline>
                                        <form-validation param="params.request" :errors="current_block.errors"></form-validation>

                                        <form-field wide>
                                            <form-select label="Request Type" id="block_collect_attribute_request_type" name="block_collect_attribute_request_type" v-model="current_block.params.request.type">
                                                <option value="speak">Speak</option>
                                                <option value="play">Play</option>
                                            </form-select>
                                            <form-validation param="params.request.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.request.type === 'speak'">
                                            <dynamic-textarea
                                                label="Request Message"
                                                name="block_collect_attribute_request"
                                                max="4096"
                                                :attributes="all_attributes"
                                                v-model="current_block.params.request.message">
                                            </dynamic-textarea>
                                            <form-validation param="params.request.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'speak'">
                                            <form-select id="block_collect_attribute_request_language" name="block_collect_attribute_request_language" label="Language" v-model="current_block.params.request.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.request.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'speak'">
                                            <form-select label="Voice" id="block_collect_attribute_request_voice" name="block_collect_attribute_request_voice" v-model="current_block.params.request.voice">
                                                <option :value="null">- Select Voice -</option>
                                                <optgroup label="Standard">
                                                    <option value="Man" v-if="current_block.params.request.language === 'en-US' || current_block.params.request.language === 'en-GB' || current_block.params.request.language === 'es-MX'">Man</option>
                                                    <option value="Woman" v-if="current_block.params.request.language === 'en-US' || current_block.params.request.language === 'en-GB' || current_block.params.request.language === 'es-MX'">Woman</option>
                                                </optgroup>
                                                <optgroup label="Premium">
                                                    <option value="Polly.Ivy" v-if="current_block.params.request.language === 'en-US'">Ivy</option>
                                                    <option value="Polly.Joanna" v-if="current_block.params.request.language === 'en-US'">Joanna</option>
                                                    <option value="Polly.Joey" v-if="current_block.params.request.language === 'en-US'">Joey</option>
                                                    <option value="Polly.Justin" v-if="current_block.params.request.language === 'en-US'">Justin</option>
                                                    <option value="Polly.Kendra" v-if="current_block.params.request.language === 'en-US'">Kendra</option>
                                                    <option value="Polly.Kimberly" v-if="current_block.params.request.language === 'en-US'">Kimberly</option>
                                                    <option value="Polly.Matthew" v-if="current_block.params.request.language === 'en-US'">Matthew</option>
                                                    <option value="Polly.Salli" v-if="current_block.params.request.language === 'en-US'">Salli</option>
                                                    <option value="Polly.Nicole" v-if="current_block.params.request.language === 'en-AU'">Nicole</option>
                                                    <option value="Polly.Russell" v-if="current_block.params.request.language === 'en-AU'">Russell</option>
                                                    <option value="Polly.Amy" v-if="current_block.params.request.language === 'en-GB'">Amy</option>
                                                    <option value="Polly.Brian" v-if="current_block.params.request.language === 'en-GB'">Brian</option>
                                                    <option value="Polly.Emma" v-if="current_block.params.request.language === 'en-GB'">Emma</option>
                                                    <option value="Polly.Mia" v-if="current_block.params.request.language === 'es-MX'">Mia</option>
                                                    <option value="Polly.Chantal" v-if="current_block.params.request.language === 'fr-CA'">Chantal</option>
                                                </optgroup>
                                            </form-select>
                                            <form-validation param="params.request.voice" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'play'">
                                            <form-select label="Audio Type" name="block_collect_attribute_request_type_audio" id="block_collect_attribute_request_type_audio" class="form-select" v-model="current_block.play_settings.request.audio_type" :disabled="current_block.params.request.attachment_key !== null">
                                                <option value="file">File Upload</option>
                                                <option value="record">Record Audio</option>
                                            </form-select>
                                        </form-field>

                                        <form-field v-if="current_block.params.request.type === 'play'">
                                            <div v-if="current_block.params.request.attachment_key === null">
                                                <div v-if="current_block.play_settings.request.audio_type === 'file'">
                                                    <form-input label="Audio File" id="block_collect_attribute_request_file" name="block_collect_attribute_request_file" ref="request_file" @change="handleFileUpload" type="file" />
                                                    <form-validation param="params.request.attachment_key" :errors="current_block.errors"></form-validation>
                                                    <form-validation param="file" :errors="current_block.errors"></form-validation>

                                                    <div class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button @click="submitFile('request')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                                <div v-if="current_block.play_settings.request.audio_type === 'record'">
                                                    <form-label label="Record Audio" for="block_collect_attribute_request_record"></form-label>
                                                    <div class="flex mx-3 mb-6 flex-nowrap">
                                                        <div class="w-10 h-10 mt-2 mr-2">
                                                            <button id="block_collect_attribute_request_record" v-bind:class="{'bg-green-500 hover:bg-green-800': (current_block.play_settings.request.status === 'waiting'), 'bg-red-500 hover:bg-red-800': (current_block.play_settings.request.status === 'recording')}" class="inline-flex items-center justify-center w-10 h-10 text-white transition-colors duration-150 rounded-full focus:shadow-outline" type="button" @click="startStopRecording('request')">
                                                                <i v-if="current_block.play_settings.request.status === 'waiting'" class="fas fa-microphone"></i>
                                                                <i v-if="current_block.play_settings.request.status === 'recording'" class="far fa-stop-circle"></i>
                                                            </button>
                                                        </div>

                                                        <div class="w-full mt-2">
                                                            <audio v-if="current_block.play_settings.request.audio_url" :src="current_block.play_settings.request.audio_url" controls class="w-full" />
                                                        </div>
                                                    </div>

                                                    <div v-if="current_block.play_settings.request.audio_url" class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="saveFile('request', 'request')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <audio v-if="current_block.params.request.attachment_key !== null" controls class="pb-2">
                                                    <source :src="'/attachments/'+current_block.params.request.attachment_key" :type="current_block.params.request.attachment_mimetype" />
                                                </audio>

                                                <div class="flex flex-wrap mx-3 mb-6 text-right">
                                                    <form-button negative-action @click="removeFile('request')"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                                                </div>
                                            </div>
                                        </form-field>
                                    </form-section>

                                    <form-section title="Valid Message" inline>
                                        <form-validation param="params.valid" :errors="current_block.errors"></form-validation>

                                        <form-field wide>
                                            <form-select label="Valid Type" id="block_collect_attribute_valid_type" name="block_collect_attribute_valid_type" v-model="current_block.params.valid.type">
                                                <option value="speak">Speak</option>
                                                <option value="play">Play</option>
                                            </form-select>
                                            <form-validation param="params.valid.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.valid.type === 'speak'">
                                            <dynamic-textarea
                                                label="Valid Message"
                                                name="block_collect_attribute_valid"
                                                max="4096"
                                                :attributes="all_attributes"
                                                v-model="current_block.params.valid.message">
                                            </dynamic-textarea>
                                            <form-validation param="params.valid.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.valid.type === 'speak'">
                                            <form-select id="block_collect_attribute_valid_language" name="block_collect_attribute_valid_language" label="Language" v-model="current_block.params.valid.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.valid.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.valid.type === 'speak'">
                                            <form-select label="Voice" id="block_collect_attribute_valid_voice" name="block_collect_attribute_valid_voice" v-model="current_block.params.valid.voice">
                                                <option :value="null">- Select Voice -</option>
                                                <optgroup label="Standard">
                                                    <option value="Man" v-if="current_block.params.valid.language === 'en-US' || current_block.params.valid.language === 'en-GB' || current_block.params.valid.language === 'es-MX'">Man</option>
                                                    <option value="Woman" v-if="current_block.params.valid.language === 'en-US' || current_block.params.valid.language === 'en-GB' || current_block.params.valid.language === 'es-MX'">Woman</option>
                                                </optgroup>
                                                <optgroup label="Premium">
                                                    <option value="Polly.Ivy" v-if="current_block.params.valid.language === 'en-US'">Ivy</option>
                                                    <option value="Polly.Joanna" v-if="current_block.params.valid.language === 'en-US'">Joanna</option>
                                                    <option value="Polly.Joey" v-if="current_block.params.valid.language === 'en-US'">Joey</option>
                                                    <option value="Polly.Justin" v-if="current_block.params.valid.language === 'en-US'">Justin</option>
                                                    <option value="Polly.Kendra" v-if="current_block.params.valid.language === 'en-US'">Kendra</option>
                                                    <option value="Polly.Kimberly" v-if="current_block.params.valid.language === 'en-US'">Kimberly</option>
                                                    <option value="Polly.Matthew" v-if="current_block.params.valid.language === 'en-US'">Matthew</option>
                                                    <option value="Polly.Salli" v-if="current_block.params.valid.language === 'en-US'">Salli</option>
                                                    <option value="Polly.Nicole" v-if="current_block.params.valid.language === 'en-AU'">Nicole</option>
                                                    <option value="Polly.Russell" v-if="current_block.params.valid.language === 'en-AU'">Russell</option>
                                                    <option value="Polly.Amy" v-if="current_block.params.valid.language === 'en-GB'">Amy</option>
                                                    <option value="Polly.Brian" v-if="current_block.params.valid.language === 'en-GB'">Brian</option>
                                                    <option value="Polly.Emma" v-if="current_block.params.valid.language === 'en-GB'">Emma</option>
                                                    <option value="Polly.Mia" v-if="current_block.params.valid.language === 'es-MX'">Mia</option>
                                                    <option value="Polly.Chantal" v-if="current_block.params.valid.language === 'fr-CA'">Chantal</option>
                                                </optgroup>
                                            </form-select>
                                            <form-validation param="params.valid.voice" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.valid.type === 'play'">
                                            <form-select label="Audio Type" name="block_collect_attribute_valid_type_audio" id="block_collect_attribute_valid_type_audio" class="form-select" v-model="current_block.play_settings.valid.audio_type" :disabled="current_block.params.valid.attachment_key !== null">
                                                <option value="file">File Upload</option>
                                                <option value="record">Record Audio</option>
                                            </form-select>
                                        </form-field>

                                        <form-field v-if="current_block.params.valid.type === 'play'">
                                            <div v-if="current_block.params.valid.attachment_key === null">
                                                <div v-if="current_block.play_settings.valid.audio_type === 'file'">
                                                    <form-input label="Audio File" id="block_collect_attribute_valid_file" name="block_collect_attribute_valid_file" ref="valid_file" @change="handleFileUpload" type="file" />
                                                    <form-validation param="params.valid.attachment_key" :errors="current_block.errors"></form-validation>
                                                    <form-validation param="file" :errors="current_block.errors"></form-validation>

                                                    <div class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button @click="submitFile('valid')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                                <div v-if="current_block.play_settings.valid.audio_type === 'record'">
                                                    <form-label label="Record Audio" for="block_collect_attribute_request_record"></form-label>
                                                    <div class="flex mx-3 mb-6 flex-nowrap">
                                                        <div class="w-10 h-10 mt-2 mr-2">
                                                            <button id="block_collect_attribute_valid_record" v-bind:class="{'bg-green-500 hover:bg-green-800': (current_block.play_settings.valid.status === 'waiting'), 'bg-red-500 hover:bg-red-800': (current_block.play_settings.valid.status === 'recording')}" class="inline-flex items-center justify-center w-10 h-10 text-white transition-colors duration-150 rounded-full focus:shadow-outline" type="button" @click="startStopRecording('valid')">
                                                                <i v-if="current_block.play_settings.valid.status === 'waiting'" class="fas fa-microphone"></i>
                                                                <i v-if="current_block.play_settings.valid.status === 'recording'" class="far fa-stop-circle"></i>
                                                            </button>
                                                        </div>

                                                        <div class="w-full mt-2">
                                                            <audio v-if="current_block.play_settings.valid.audio_url" :src="current_block.play_settings.valid.audio_url" controls class="w-full" />
                                                        </div>
                                                    </div>

                                                    <div v-if="current_block.play_settings.valid.audio_url" class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="saveFile('valid', 'valid')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <audio v-if="current_block.params.valid.attachment_key !== null" controls class="pb-2">
                                                    <source :src="'/attachments/'+current_block.params.valid.attachment_key" :type="current_block.params.valid.attachment_mimetype" />
                                                </audio>

                                                <div class="flex flex-wrap mx-3 mb-6 text-right">
                                                    <form-button negative-action @click="removeFile('valid')"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                                                </div>
                                            </div>
                                        </form-field>
                                    </form-section>

                                    <form-section title="Invalid Message" inline>
                                        <form-validation param="params.invalid" :errors="current_block.errors"></form-validation>

                                        <form-field wide>
                                            <form-select label="Invalid Type" id="block_collect_attribute_invalid_type" name="block_collect_attribute_invalid_type" v-model="current_block.params.invalid.type">
                                                <option value="speak">Speak</option>
                                                <option value="play">Play</option>
                                            </form-select>
                                            <form-validation param="params.invalid.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.invalid.type === 'speak'">
                                            <dynamic-textarea
                                                label="Invalid Message"
                                                name="block_collect_attribute_invalid"
                                                max="4096"
                                                :attributes="all_attributes"
                                                v-model="current_block.params.invalid.message">
                                            </dynamic-textarea>
                                            <form-validation param="params.invalid.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'speak'">
                                            <form-select id="block_collect_attribute_invalid_language" name="block_collect_attribute_invalid_language" label="Language" v-model="current_block.params.invalid.language">
                                                <option :value="null">- Select Language -</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="en-AU">English (AU)</option>
                                                <option value="fr-CA">French (CA)</option>
                                                <option value="es-MX">Spanish (MX)</option>
                                            </form-select>
                                            <form-validation param="params.invalid.language" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'speak'">
                                            <form-select label="Voice" id="block_collect_attribute_invalid_voice" name="block_collect_attribute_invalid_voice" v-model="current_block.params.invalid.voice">
                                                <option :value="null">- Select Voice -</option>
                                                <optgroup label="Standard">
                                                    <option value="Man" v-if="current_block.params.invalid.language === 'en-US' || current_block.params.invalid.language === 'en-GB' || current_block.params.invalid.language === 'es-MX'">Man</option>
                                                    <option value="Woman" v-if="current_block.params.invalid.language === 'en-US' || current_block.params.invalid.language === 'en-GB' || current_block.params.invalid.language === 'es-MX'">Woman</option>
                                                </optgroup>
                                                <optgroup label="Premium">
                                                    <option value="Polly.Ivy" v-if="current_block.params.invalid.language === 'en-US'">Ivy</option>
                                                    <option value="Polly.Joanna" v-if="current_block.params.invalid.language === 'en-US'">Joanna</option>
                                                    <option value="Polly.Joey" v-if="current_block.params.invalid.language === 'en-US'">Joey</option>
                                                    <option value="Polly.Justin" v-if="current_block.params.invalid.language === 'en-US'">Justin</option>
                                                    <option value="Polly.Kendra" v-if="current_block.params.invalid.language === 'en-US'">Kendra</option>
                                                    <option value="Polly.Kimberly" v-if="current_block.params.invalid.language === 'en-US'">Kimberly</option>
                                                    <option value="Polly.Matthew" v-if="current_block.params.invalid.language === 'en-US'">Matthew</option>
                                                    <option value="Polly.Salli" v-if="current_block.params.invalid.language === 'en-US'">Salli</option>
                                                    <option value="Polly.Nicole" v-if="current_block.params.invalid.language === 'en-AU'">Nicole</option>
                                                    <option value="Polly.Russell" v-if="current_block.params.invalid.language === 'en-AU'">Russell</option>
                                                    <option value="Polly.Amy" v-if="current_block.params.invalid.language === 'en-GB'">Amy</option>
                                                    <option value="Polly.Brian" v-if="current_block.params.invalid.language === 'en-GB'">Brian</option>
                                                    <option value="Polly.Emma" v-if="current_block.params.invalid.language === 'en-GB'">Emma</option>
                                                    <option value="Polly.Mia" v-if="current_block.params.invalid.language === 'es-MX'">Mia</option>
                                                    <option value="Polly.Chantal" v-if="current_block.params.invalid.language === 'fr-CA'">Chantal</option>
                                                </optgroup>
                                            </form-select>
                                            <form-validation param="params.invalid.voice" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'play'">
                                            <form-select label="Audio Type" name="block_collect_attribute_invalid_type_audio" id="block_collect_attribute_invalid_type_audio" class="form-select" v-model="current_block.play_settings.invalid.audio_type" :disabled="current_block.params.invalid.attachment_key !== null">
                                                <option value="file">File Upload</option>
                                                <option value="record">Record Audio</option>
                                            </form-select>
                                        </form-field>

                                        <form-field v-if="current_block.params.invalid.type === 'play'">
                                            <div v-if="current_block.params.invalid.attachment_key === null">
                                                <div v-if="current_block.play_settings.invalid.audio_type === 'file'">
                                                    <form-input label="Audio File" id="block_collect_attribute_invalid_file" name="block_collect_attribute_invalid_file" ref="invalid_file" @change="handleFileUpload" type="file" />
                                                    <form-validation param="params.invalid.attachment_key" :errors="current_block.errors"></form-validation>
                                                    <form-validation param="file" :errors="current_block.errors"></form-validation>

                                                    <div class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button @click="submitFile('invalid')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                                <div v-if="current_block.play_settings.invalid.audio_type === 'record'">
                                                    <form-label label="Record Audio" for="block_collect_attribute_request_record"></form-label>
                                                    <div class="flex mx-3 mb-6 flex-nowrap">
                                                        <div class="w-10 h-10 mt-2 mr-2">
                                                            <button id="block_collect_attribute_invalid_record" v-bind:class="{'bg-green-500 hover:bg-green-800': (current_block.play_settings.invalid.status === 'waiting'), 'bg-red-500 hover:bg-red-800': (current_block.play_settings.invalid.status === 'recording')}" class="inline-flex items-center justify-center w-10 h-10 text-white transition-colors duration-150 rounded-full focus:shadow-outline" type="button" @click="startStopRecording('invalid')">
                                                                <i v-if="current_block.play_settings.invalid.status === 'waiting'" class="fas fa-microphone"></i>
                                                                <i v-if="current_block.play_settings.invalid.status === 'recording'" class="far fa-stop-circle"></i>
                                                            </button>
                                                        </div>

                                                        <div class="w-full mt-2">
                                                            <audio v-if="current_block.play_settings.invalid.audio_url" :src="current_block.play_settings.invalid.audio_url" controls class="w-full" />
                                                        </div>
                                                    </div>

                                                    <div v-if="current_block.play_settings.invalid.audio_url" class="flex flex-wrap mx-3 mt-3 mb-6">
                                                        <form-button positive-action @click="saveFile('invalid', 'invalid')"><i class="fas fa-plus-circle"></i> UPLOAD</form-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <audio v-if="current_block.params.invalid.attachment_key !== null" controls class="pb-2">
                                                    <source :src="'/attachments/'+current_block.params.invalid.attachment_key" :type="current_block.params.invalid.attachment_mimetype" />
                                                </audio>

                                                <div class="flex flex-wrap mx-3 mb-6 text-right">
                                                    <form-button negative-action @click="removeFile('invalid')"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                                                </div>
                                            </div>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Add to Segment Block -->
                                <div v-if="current_block.messaging_type === 'add_to_segment'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Add to Segment Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Add a caller to a segment.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Segment" id="block_add_to_segment_segment_id" name="block_add_to_segment_segment_id" v-model="current_block.params.segment_id">
                                                <option :value="null">- Select Segment -</option>
                                                <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                                            </form-select>
                                            <form-validation param="params.segment_id" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Date Gate Block -->
                                <div v-if="current_block.messaging_type === 'date_gate'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Date Gate Block</div>
                                    </div>
                                    <div class="mb-2">
                                        <p class="text-sm italic text-gray-500">Determine path based on current date/time.</p>
                                    </div>
                                    <form-validation param="params" :errors="current_block.errors" />

                                    <form-section clear>
                                        <form-field>
                                            <form-datepicker id="block_date_gate_date_start" name="block_date_gate_date_start" label="Date Start" v-model="current_block.params.date_start" type="datetime" format="YYYY-MM-DD HH:mm:ss" valueFormat="format"></form-datepicker>
                                            <form-validation param="params.date_start" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                        <form-field>
                                            <form-datepicker id="block_date_gate_date_end" name="block_date_gate_date_end" label="Date End" v-model="current_block.params.date_end" type="datetime" format="YYYY-MM-DD HH:mm:ss" valueFormat="format"></form-datepicker>
                                            <form-validation param="params.date_end" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Date Gate Before Block -->
                                <div v-if="current_block.messaging_type === 'date_before'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Before Block</div>
                                    </div>
                                    <div class="mb-2">
                                        <p class="text-sm italic text-gray-500">Occurs before specified date range.</p>
                                    </div>
                                    <form-validation param="params" :errors="current_block.errors" />
                                </div>

                                <!-- Date Gate During Block -->
                                <div v-if="current_block.messaging_type === 'date_during'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Date Gate Block</div>
                                    </div>
                                    <div class="mb-2">
                                        <p class="text-sm italic text-gray-500">Occurs during specified date range.</p>
                                    </div>
                                    <form-validation param="params" :errors="current_block.errors" />
                                </div>

                                <!-- Date Gate After Block -->
                                <div v-if="current_block.messaging_type === 'date_after'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Date Gate Block</div>
                                    </div>
                                    <div class="mb-2">
                                        <p class="text-sm italic text-gray-500">Occurs after specified date range.</p>
                                    </div>
                                    <form-validation param="params" :errors="current_block.errors" />
                                </div>

                                <!-- Date Condition Block -->
                                <div v-if="current_block.messaging_type === 'date_conditional'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Date Conditional Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Check a set of date/time criteria and perform an action based on result.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <h2>Conditions</h2>
                                    <div class="w-full">
                                        <!-- Row 1 -->
                                        <div class="flex flex-wrap mb-6 -mx-3">
                                            <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                                <form-select label="Join By" id="block_date_conditional_operator" name="block_date_conditional_operator" v-model="current_block.params.conditions.operator">
                                                    <option value="AND">AND</option>
                                                    <option value="OR">OR</option>
                                                </form-select>
                                                <form-validation param="params.conditions.operator" :errors="current_block.errors"></form-validation>
                                            </div>
                                        </div>

                                        <!-- Conditions -->
                                        <div class="flex flex-wrap mb-6 -mx-3" v-for="(condition, condition_index) in current_block.params.conditions.conditions" v-bind:key="condition_index">
                                            <form-validation :param="'params.conditions.conditions.'+condition_index" :errors="current_block.errors"></form-validation>

                                            <div class="w-full px-3 mb-6 md:w-2/5 md:mb-0">
                                                <form-select label="Type" :id="'block_date_conditional_'+condition_index+'_type'" :name="'block_date_conditional_'+condition_index+'_type'" v-model="condition.type">
                                                    <option value="day_of_week">Day of Week</option>
                                                    <option value="time_of_day">Time of Day</option>
                                                </form-select>
                                                <form-validation :param="'params.conditions.conditions.'+condition_index+'.type'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0">
                                                <form-select label="Operator" :id="'block_date_conditional_'+condition_index+'_operator'" :name="'block_date_conditional_'+condition_index+'_operator'" v-model="condition.operator">
                                                    <option value="=">Equal To</option>
                                                    <option value="!=">Not Equal To</option>
                                                    <option value="<">Less Than</option>
                                                    <option value="<=">Less Than Or Equal To</option>
                                                    <option value=">">Greater Than</option>
                                                    <option value=">=">Greater Than Or Equal To</option>
                                                    <option value="exists">Exists</option>
                                                    <option value="not_exists">Does Not Exist</option>
                                                </form-select>
                                                <form-validation :param="'params.conditions.conditions.'+condition_index+'.operator'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="condition.type === 'day_of_week'">
                                                <form-select label="Day of Week" :id="'block_date_conditional_'+condition_index+'_value'" :name="'block_date_conditional_'+condition_index+'_value'" v-model="condition.value">
                                                    <option :value="null">- Select Day -</option>
                                                    <option value="0">Sunday</option>
                                                    <option value="1">Monday</option>
                                                    <option value="2">Tuesday</option>
                                                    <option value="3">Wednesday</option>
                                                    <option value="4">Thursday</option>
                                                    <option value="5">Friday</option>
                                                    <option value="6">Saturday</option>
                                                </form-select>
                                                <form-validation :param="'params.conditions.conditions.'+condition_index+'.value'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="condition.type === 'time_of_day'">
                                                <form-input label="Value" :id="'block_date_conditional_'+condition_index+'_value'" :name="'block_date_conditional_'+condition_index+'_value'" v-model="condition.value" placeholder="00:00" />
                                                <form-validation :param="'params.conditions.conditions.'+condition_index+'.value'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full h-full pt-6 pl-6 text-right cursor-pointer md:w-1/5">
                                                <form-button negative-action @click="removeDateCondition(condition_index)"><i class="fas fa-minus-circle"></i></form-button>
                                            </div>
                                        </div>

                                        <div class="flex flex-wrap mx-3 mb-6">
                                            <form-button positive-action @click="addDateCondition()"><i class="fas fa-plus-circle"></i> ADD</form-button>
                                        </div>

                                        <form-validation param="params.conditions.conditions" :errors="current_block.errors"></form-validation>
                                    </div>
                                </div>

                                <!-- Date Condition Block -->
                                <div v-if="current_block.messaging_type === 'date_condition'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Date Conditions Met</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Execute items if the conditions specified are met.</div>
                                </div>

                                <!-- Date Condition Default Block -->
                                <div v-if="current_block.messaging_type === 'date_condition_default'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Date Conditions Not Met</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Execute items if the conditions specified are not met.</div>
                                </div>

                                <!-- Dynamic Content Block -->
                                <div v-if="current_block.messaging_type === 'dynamic_content'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Dynamic Content Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Send an HTTP post out to a 3rd party service, providing dynamic interactions.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <dynamic-textarea
                                        label="URL"
                                        name="block_dynamic_content_url"
                                        max="1600"
                                        v-model="current_block.params.url"
                                        :attributes="all_attributes"
                                    >
                                    </dynamic-textarea>
                                    <form-validation :param="'blocks.'+current_block_index+'.params.url'" :errors="errors"></form-validation>
                                </div>

                                <!-- Dynamic Stream Block -->
                                <div v-if="current_block.messaging_type === 'dynamic_stream'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Dynamic Stream Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Connect to an external stream for bi-directional voice interactions.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <dynamic-textarea
                                        label="URL"
                                        name="block_dynamic_stream_url"
                                        max="1600"
                                        v-model="current_block.params.url"
                                        :attributes="all_attributes"
                                    >
                                    </dynamic-textarea>
                                    <form-validation :param="'blocks.'+current_block_index+'.params.url'" :errors="errors"></form-validation>
                                </div>

                                <!-- Notification Block -->
                                <div v-if="current_block.messaging_type === 'notification'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Notification Block</div>
                                    </div>
                                     <div class="pb-4 text-sm italic text-gray-500">Send a notification outside the system during or after a sequence interaction.</div>
                                    <form-validation param="params" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field wide>
                                            <form-select label="Notification Type" id="block_notification_type" name="block_notification_type" v-model="current_block.params.type" @change="clearBlockErrors(current_block)">
                                                <option :value="null">- Select Notification Type -</option>
                                                <option value="email">Email</option>
                                                <option value="url_get">URL - GET Request</option>
                                                <option value="url_post_xml">URL - POST Request (XML)</option>
                                                <option value="url_post_json">URL - POST Request (JSON)</option>
                                                <!--<option value="sms">SMS</option>-->
                                            </form-select>
                                            <form-validation param="params.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.type === 'email' || current_block.params.type === 'sms'">
                                            <form-input label="Recipient" id="block_notification_recipient" name="block_notification_recipient" type="text" v-model="current_block.params.recipient" />
                                            <form-validation param="params.recipient" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.type === 'email'">
                                            <dynamic-textarea
                                                label="Subject"
                                                name="block_notification_subject"
                                                max="1600"
                                                v-model="current_block.params.subject"
                                                :attributes="all_attributes">
                                            </dynamic-textarea>
                                            <form-validation param="params.subject" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.type === 'url_get' || current_block.params.type === 'url_post_xml' || current_block.params.type === 'url_post_json'">
                                            <dynamic-textarea
                                                label="URL"
                                                name="block_notification_url"
                                                max="1600"
                                                v-model="current_block.params.url"
                                                :attributes="all_attributes">
                                            </dynamic-textarea>
                                            <form-validation param="params.url" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.params.type !== 'url_get'">
                                            <dynamic-textarea
                                                label="Body"
                                                name="block_notification_text"
                                                max="1600"
                                                v-model="current_block.params.text"
                                                :attributes="all_attributes">
                                            </dynamic-textarea>
                                            <form-validation param="params.text" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Create Sequence
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>

        <BlockModal
            :show="open_block_modal"
            :settings="settings"
            v-model:currentID="current_id"
            @add="(block) => addBlock(block)"
            @cancel="open_block_modal = false;"
        />

        <RepLookupModal
            :show="open_rep_summary_modal"
            :level="(current_block && current_block.messaging_type === 'rep_lookup' ? (current_block.params.level) : null)"
            :type="(current_block && current_block.messaging_type === 'rep_lookup' ? (current_block.params.type) : null)"
            :repSummary="rep_summary"
            @close="open_rep_summary_modal = false;"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormFile from '../../shared/form/File';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import FormDatepicker from '../../shared/form/DatePicker';
    import DynamicTextarea from '../../shared/DynamicTextarea'
    import BlockModal from '../../shared/modal/BlockModal'
    import RepLookupModal from '../../shared/modal/RepLookupModal'
    import Builder from './Builder'
    import MicRecorder from 'mic-recorder-to-mp3'

    export default {
        name: 'CreateSequence',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormFile,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            FormDatepicker,
            DynamicTextarea,
            Builder,
            BlockModal,
            RepLookupModal,
            MicRecorder,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetchNumbers();
            this.fetchAttributes();
            this.fetchSequences();
            this.fetchSegments();
            this.fetchSettings();

            //this.addBlock('speak')
        },
        data() {
            return {
                numbers: [],
                attributes: [],
                all_attributes: [],
                segments: [],
                sequences: [],
                dial_lists: [],
                sequence: {
                    name: '',
                    number_id: null,
                    status: 'active',
                    blocks: []
                },
                rep_summary: null,
                current_block: null,
                errors: [],
                block_errors: {},
                attachment_file: null,
                current_block_index: null,
                current_id: 0,
                current_block: null,
                rep_summary_demo_value: 'MO',
                reps: [],
                reps_loading: false,
                settings: {
                    talk: {
                        features: {
                            dial_lists: false,
                            record: false,
                            reports: false,
                            speech_to_text: false,
                            transcribe: false,
                        },
                        maximum_numbers: null,
                        maximum_sequences: null,
                    },
                    lookup: {
                        features: {
                            rep_lookup_federal: false,
                            state_lookup_federal: false,
                        },
                    },
                },
                show_help_section: null,
                open_block_modal: false,
                open_rep_summary_modal: false,
            }
        },
        methods: {
            fetchSettings() {
                this.$http.get(`/api/v1/me`).then(this.refreshSettings);
            },
            refreshSettings({data}) {
                this.settings = data.data.settings;

                if (this.settings.talk.features.dial_lists) {
                    this.fetchDialLists()
                }
            },
            fetchNumbers() {
                this.$http.get(`/api/v1/numbers?per_page=-1`).then(this.refreshNumbers);
            },
            refreshNumbers({data}) {
                this.numbers = data.data;
            },
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                this.$http.get(`/api/v1/attributes?per_page=-1`).then(this.refreshAttributes);
            },
            refreshAttributes({data}) {
                for(let index in data.data) {
                    this.all_attributes.push(data.data[index]);

                    if (data.data[index].scope !== 'system') {
                        this.attributes.push(data.data[index]);
                    }
                }
            },
            fetchSegments() {
                this.$http.get(`/api/v1/segments?search_types=static&per_page=-1`).then(this.refreshSegments);
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchDialLists() {
                this.$http.get(`/api/v1/diallists?per_page=-1`).then(this.refreshDialLists);
            },
            refreshDialLists({data}) {
                this.dial_lists = data.data;
            },
            save() {
                this.$http.post('/api/v1/sequences', this.sequence).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'The sequence has been created',
                        duration: 5000,
                        speed: 1000
                    });

                    this.$router.push({
                        name: 'list_sequences'
                    });
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {}
                        this.block_errors = {}
                        this.clearAllBlockErrors(this.sequence.blocks)

                        for (var block_index in this.errors) {
                            if (block_index.substr(0, 6) === 'blocks') {
                                var full_index = block_index.substring(7, block_index.lastIndexOf('.params'))
                                var param = block_index.substring(block_index.lastIndexOf('.params') + 1)

                                if (!this.block_errors[full_index]) {
                                    this.block_errors[full_index] = {}
                                }

                                if (!this.block_errors[full_index][param]) {
                                    this.block_errors[full_index][param] = []
                                }

                                this.block_errors[full_index][param] = this.errors[block_index]

                                full_index = block_index.substring(7, block_index.lastIndexOf('.blocks'))
                                param = block_index.substring(block_index.lastIndexOf('.blocks') + 1)

                                if (!this.block_errors[full_index]) {
                                    this.block_errors[full_index] = {}
                                }

                                if (!this.block_errors[full_index][param]) {
                                    this.block_errors[full_index][param] = []
                                }

                                this.block_errors[full_index][param] = this.errors[block_index]
                            }
                        }

                        this.checkBlockErrors(this.sequence.blocks, '')
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while creating the sequence',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            cancel() {
                this.$router.back();
            },
            clearBlockErrors(block) {
                block.errors = []
            },
            clearAllBlockErrors(blocks) {
                for (var block_index in blocks) {
                    blocks[block_index].errors = []

                    if (blocks[block_index].messaging_type === 'menu' || blocks[block_index].messaging_type === 'menu_item' || blocks[block_index].messaging_type === 'random' || blocks[block_index].messaging_type === 'random_item'
                     || blocks[block_index].messaging_type === 'poll' || blocks[block_index].messaging_type === 'poll_item' || blocks[block_index].messaging_type === 'absplit' || blocks[block_index].messaging_type === 'absplit_item') {
                        this.clearAllBlockErrors(blocks[block_index].blocks)
                    }
                }
            },
            checkBlockErrors(blocks, parent_index) {
                for (var block_index in blocks) {
                    let display_index = this.getDisplayIndex(parent_index, block_index)

                    if (display_index in this.block_errors) {
                        blocks[block_index].errors = this.block_errors[display_index]
                    }

                    if (blocks[block_index].messaging_type === 'menu' || blocks[block_index].messaging_type === 'menu_item' || blocks[block_index].messaging_type === 'random' || blocks[block_index].messaging_type === 'random_item'
                     || blocks[block_index].messaging_type === 'poll' || blocks[block_index].messaging_type === 'poll_item' || blocks[block_index].messaging_type === 'absplit' || blocks[block_index].messaging_type === 'absplit_item') {
                        this.checkBlockErrors(blocks[block_index].blocks, display_index)
                    }
                }
            },
            getDisplayIndex(parent_index, block_index) {
                return ((parent_index === '' ? '' : parent_index + '.') + block_index)
            },
            addBlockDialog() {
                this.open_block_modal = true;
            },
            addBlock(block) {
                this.sequence.blocks.push(block);
                this.setActiveBlock(block);
                this.open_block_modal = false;
            },
            setActiveBlock(block) {
                this.current_block = block;
            },
            removeBlock(block, parent = null) {
                this.current_block = null;
                this.block_errors = {};
                this.errors = [];

                if (parent === null) {
                    parent = this.sequence
                }

                if (parent.blocks) {
                    for (let i = 0 ; i < parent.blocks.length ; ++i) {
                        if (parent.blocks[i].id === block.id) {
                            parent.blocks.splice(i, 1);
                            break
                        }

                        this.removeBlock(block, parent.blocks[i])
                    }
                }
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
            getBlocksLength() {
                return this.current_block.blocks.length
            },
            findItemByID(id, parent) {
                if (parent.hasOwnProperty(blocks)) {
                    if (parent.id === id) {
                        return
                    }

                    for (let i = 0 ; i < parent.blocks ; ++i) {
                        this.findItemByID(id, parent.blocks[i])
                    }
                }
            },
            beforeMove ({ dragItem, pathFrom, pathTo }) {
                if (dragItem.messaging_type === 'true' || dragItem.messaging_type === 'false') {
                    return false
                }

                return true
            },
            addCondition() {
                this.current_block.params.conditions.conditions.push({
                    attribute_id: null,
                    segment_id: null,
                    operator: '=',
                    value: ''
                });
            },
            removeCondition(condition_index) {
                this.current_block.params.conditions.conditions.splice(condition_index, 1);
            },
            addDateCondition() {
                this.current_block.params.conditions.conditions.push({
                    type: 'day_of_week',
                    operator: '=',
                    value: null,
                });
            },
            removeDateCondition(condition_index) {
                this.current_block.params.conditions.conditions.splice(condition_index, 1);
            },
            addNumber() {
                this.current_block.params.numbers.push({
                    number: '',
                    send_digits: '',
                });
            },
            removeNumber(number_index) {
                this.current_block.params.numbers.splice(number_index, 1);
            },
            submitFile(offset) {
                let formData = new FormData();
                formData.append('file', this.attachment_file);

                this.$http.post('/api/v1/attachments', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (offset) {
                        this.current_block.params[offset].attachment_key = response.data.attachment_key;
                        this.current_block.params[offset].attachment_mimetype = response.data.attachment_mimetype;
                    } else {
                        this.current_block.params.attachment_key = response.data.attachment_key;
                        this.current_block.params.attachment_mimetype = response.data.attachment_mimetype;
                    }
                }).catch(error => {
                    this.current_block.errors = error.response.data.errors || {}

                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while uploading the attachment',
                        duration: 5000,
                        speed: 1000
                    });
                });
            },
            startStopRecording(offset) {
                let ref = this.current_block.play_settings[offset]

                if (ref.status === 'waiting') {
                    ref.recorder.start().then(() => {
                        ref.status = 'recording';
                    }).catch((e) => {
                        console.log(e)
                    })
                } else if (ref.status === 'recording') {
                    ref.recorder.stop().getMp3().then(([buffer, blob]) => {
                        const file = new File(buffer, 'audio.mp3', {
                            type: blob.type,
                            lastModified: Date.now()
                        });

                        ref.audio_url = URL.createObjectURL(file)
                        ref.status = 'waiting';
                    })
                }
            },
            async saveFile(offset, params_offset) {
                let ref = this.current_block.play_settings[offset]
                let form_data = new FormData();
                let blob = await fetch(ref.audio_url).then(r => r.blob());
                form_data.append('file', new Blob([blob], { type: 'audio/wav' }));

                this.$http.post('/api/v1/attachments', form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (params_offset) {
                        this.current_block.params[params_offset].attachment_key = response.data.attachment_key;
                        this.current_block.params[params_offset].attachment_mimetype = response.data.attachment_mimetype;
                    } else {
                        this.current_block.params.attachment_key = response.data.attachment_key;
                        this.current_block.params.attachment_mimetype = response.data.attachment_mimetype;
                    }
                }).catch(error => {
                    this.current_block.errors = error.response.data.errors || {}

                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while uploading the attachment',
                        duration: 5000,
                        speed: 1000
                    });
                });
            },
            removeFile(offset) {
                if (offset && this.current_block.params[offset].attachment_key) {
                    this.current_block.params[offset].attachment_key = null
                    this.current_block.params[offset].attachment_mimetype = null
                } else {
                    this.current_block.params.attachment_key = null
                    this.current_block.params.attachment_mimetype = null
                }
            },
            handleFileUpload(files) {
                this.attachment_file = files[0];
            },
            getRepSummary() {
                let endpoint = '';

                if (this.current_block.params.filter_by === 'zip') {
                    endpoint = 'zipcodes/' + (this.current_block.params.value_type === 'set_value' ? this.current_block.params.value : '63051') + '/reps'
                } else if (this.current_block.params.filter_by === 'state') {
                    endpoint = 'states/' + (this.current_block.params.value_type === 'set_value' ? this.current_block.params.value : 'MO') + '/reps'
                } else if (this.current_block.params.filter_by === 'district') {
                    endpoint = 'districts/' + (this.current_block.params.value_type === 'set_value' ? this.current_block.params.value : '1') + '/reps'
                }

                let url = process.env.MIX_LOOKUP_URL + `${endpoint}?level=${this.current_block.params.level}&type=${this.current_block.params.type}`

                if (this.current_block.params.party !== null) {
                    url += '&party=' + this.current_block.params.party
                }

                if (this.current_block.params.include_reps.length > 0) {
                    url += '&include_ids=' + this.current_block.params.include_reps.map(function(item){ return item.id }).join(',')
                }

                if (this.current_block.params.exclude_reps.length > 0) {
                    url += '&exclude_ids=' + this.current_block.params.exclude_reps.map(function(item){ return item.id }).join(',')
                }

                return this.$http.get(url).then(this.refreshRepSummary)
            },
            refreshRepSummary({data}) {
                this.rep_summary = data.data;
            },
            showRepSummaryDialog() {
                this.getRepSummary().then(() => {
                    this.open_rep_summary_modal = true;
                })
            },
            searchReps(query) {
                if (query === '')
                    return

                this.reps_loading = true
                return this.$http.get(process.env.MIX_LOOKUP_URL + 'search/reps?search='+query).then(this.loadReps)
            },
            loadReps({data}) {
                this.reps = data.representatives
                this.reps_loading = false
            },
            updateMenuChoices(choices) {
                if (choices > 9) {
                    choices = 9
                    return
                } else if (choices < 1) {
                    choices = 1;
                    return;
                }

                if (choices > this.current_block.blocks.length) {
                    let new_count = (choices - this.current_block.blocks.length);

                    for (let i = 0 ; i < new_count ; ++i) {
                        this.current_block.blocks.push({
                            'id': ('new_'+(this.current_id += 1)),
                            'messaging_type': 'menu_item',
                            'params': {
                                'key': '0',
                                'name': 'Choice 1',
                                'attribute_value': null,
                            },
                            'blocks': [],
                            'errors': [],
                        });
                    }
                } else if (choices < this.current_block.blocks.length) {
                    this.current_block.blocks.splice(choices);
                }
            },
            updatePollChoices(choices) {
                if (choices > 9) {
                    choices = 9
                    return
                } else if (choices < 1) {
                    choices = 1;
                    return;
                }

                if (choices > this.current_block.blocks.length) {
                    let new_count = (choices - this.current_block.blocks.length);

                    for (let i = 0 ; i < new_count ; ++i) {
                        this.current_block.blocks.push({
                            'id': ('new_'+(this.current_id += 1)),
                            'messaging_type': 'poll_item',
                            'params': {
                                'key': '0',
                                'name': 'Choice 1',
                                'attribute_value': null,
                            },
                            'blocks': [],
                            'errors': [],
                        });
                    }
                } else if (choices < this.current_block.blocks.length) {
                    this.current_block.blocks.splice(choices);
                }
            },
            updateDialSource() {
                if (this.current_block.params.source === 'numbers') {
                    this.current_block.params.numbers = [
                        {
                            'number': '',
                            'send_digits': '',
                        },
                    ]
                } else {
                    this.current_block.params.dial_list_id = null
                }
            },
            updateRandomChoices(choices) {
                if (choices > 9) {
                    choices = 9
                    return
                } else if (choices < 1) {
                    choices = 1;
                    return;
                }

                if (choices > this.current_block.blocks.length) {
                    let new_count = (choices - this.current_block.blocks.length);

                    for (let i = 0 ; i < new_count ; ++i) {
                        this.current_block.blocks.push({
                            'id': ('new_'+(this.current_id += 1)),
                            'messaging_type': 'random_item',
                            'params': {
                                'percent': 100,
                                'name': 'Choice 1',
                                'attribute_value': null,
                            },
                            'blocks': [],
                            'errors': [],
                        });
                    }
                } else if (choices < this.current_block.blocks.length) {
                    this.current_block.blocks.splice(choices);
                }
            },
            updateABSplitChoices(choices) {
                if (choices > 9) {
                    choices = 9
                    return
                } else if (choices < 1) {
                    choices = 1;
                    return;
                }

                if (choices > this.current_block.blocks.length) {
                    let new_count = (choices - this.current_block.blocks.length);

                    for (let i = 0 ; i < new_count ; ++i) {
                        this.current_block.blocks.push({
                            'id': ('new_'+(this.current_id += 1)),
                            'messaging_type': 'absplit_item',
                            'params': {
                                'percent': 100,
                                'name': 'Choice 1',
                                'attribute_value': null,
                            },
                            'blocks': [],
                            'errors': [],
                        });
                    }
                } else if (choices < this.current_block.blocks.length) {
                    this.current_block.blocks.splice(choices);
                }
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }

</script>
