<template>
    <div>
        <layout-header title="Segments">
            <layout-button href="/segments/create">Create New Segment</layout-button>
        </layout-header>

        <filter-filter cols.number="2">
            <filter-column>
                <form-input id="search" name="search" label="" v-model="search" placeholder="Search by name and press ↵" @keyupenter="updateSearch()"></form-input>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_types"
                    :options="types"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Type"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_statuses"
                    :options="statuses"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Status"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
        </filter-filter>

        <table-table>
            <template v-slot:header>
                <table-row type="header">
                    <table-header @click="updateSort('id')" hide-sm>ID</table-header>
                    <table-header @click="updateSort('name')">Name</table-header>
                    <table-header @click="updateSort('type')">Type</table-header>
                    <table-header @click="updateSort('people_count')">People</table-header>
                    <table-header @click="updateSort('status')" hide-sm>Status</table-header>
                    <table-header type="text" last>Actions</table-header>
                </table-row>
            </template>
            <template v-slot:body>
                <table-row v-for="(segment, index) in segments" :key="segment.id" :index="index">
                    <table-cell hide-sm bold>{{ segment.id }}</table-cell>
                    <table-cell>{{ segment.name }}</table-cell>
                    <table-cell>{{ $filters.capitalize(segment.type) }}</table-cell>
                    <table-cell>{{ (segment.type === 'static' ? segment.people_count : '--') }}</table-cell>
                    <table-cell hide-sm>
                        <table-status-badge :status="segment.status"></table-status-badge>
                    </table-cell>
                    <table-cell last>
                        <table-edit-link :href="{ name: 'edit_segment', params: { id: segment.id }}"></table-edit-link>
                        <table-delete-link @click="confirmDelete(segment.id)"></table-delete-link>
                    </table-cell>
                </table-row>
            </template>
        </table-table>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <ConfirmModal
            name="delete-confirm"
            height="220"
            title="Delete Segment?"
            description="Are you sure you want to delete this segment?  This action cannot be undone."
            actionText="Delete"
            :show="open_delete_modal"
            @confirm="deleteSegment()"
            @cancel="open_delete_modal = false"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'
    import LayoutButton from '../../shared/layout/Button'
    import FilterFilter from '../../shared/filter/Filter'
    import FilterColumn from '../../shared/filter/Column'
    import TableTable from '../../shared/table/Table'
    import TableRow from '../../shared/table/Row'
    import TableHeader from '../../shared/table/Header'
    import TableCell from '../../shared/table/Cell'
    import TableStatusBadge from '../../shared/table/StatusBadge'
    import TableEditLink from '../../shared/table/EditLink'
    import TableDeleteLink from '../../shared/table/DeleteLink'
    import ConfirmModal from '../../shared/modal/ConfirmModal'
    import FormField from '../../shared/form/Field'
    import FormInput from '../../shared/form/Input'
    import FormSelect from '../../shared/form/Select'

    export default {
        name: 'ListSegments',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            FilterFilter,
            FilterColumn,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
            TableStatusBadge,
            TableEditLink,
            TableDeleteLink,
            FormField,
            FormInput,
            FormSelect,
            ConfirmModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetch(1);
        },
        data() {
            return {
                segments: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                statuses: [
                    {id: 'active', name: 'Active'},
                    {id: 'inactive', name: 'Inactive'},
                ],
                types: [
                    {id: 'static', name: 'Static'},
                    {id: 'dynamic', name: 'Dynamic'},
                ],
                search: this.$route.query.search || '',
                search_statuses: [],
                search_types: [],
                open_delete_modal: false,
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_statuses = this.search_statuses.map(status => status.id)
                let search_types = this.search_types.map(type => type.id)

                this.$http.get(`/api/v1/segments?page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_statuses=${search_statuses}&search_types=${search_types}`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.segments = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            confirmDelete(id) {
                this.delete_id = id;
                this.open_delete_modal = true;
            },
            deleteSegment() {
                this.$http.delete(`/api/v1/segments/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.open_delete_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The segment has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
        }
    }
</script>
