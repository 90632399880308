<template>
    <div class="flex-1 text-center px-4 py-2 m-2" v-bind:class="{'hidden md:table-cell': hideSm, 'hidden lg:table-cell': hideMd, 'hidden xl:table-cell': hideLg}">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'filter-column',
        props: {
            hideSm: Boolean,
            hideMd: Boolean,
            hideLg: Boolean,
        },
    };
</script>
