<template>
    <a
        :href="href"
        @click="$emit('click')"
    >
        <button
            class="mr-5 text-black font-bold cursor-pointer rounded-full hover:bg-opacity-50 hover:text-black transition duration-500 ease-in-out whitespace-nowrap"
            :class="{
                'bg-white border-2 border-gray-700 hover:bg-gray-400 px-8 py-1': secondary,
                'text-green-500 hover:text-green-700 px-4 py-2': positiveAction,
                'text-red-500 hover:text-red-700 hover:text-red-700 px-4 py-2': negativeAction,
                'bg-white border-2 border-sbr-dark-orange hover:bg-sbr-dark-orange px-8 py-1': (!secondary && !positiveAction && !negativeAction),
            }"
        >
            <slot></slot>
        </button>
    </a>
</template>

<script>

    export default {
        name: 'layout-button',
        emits: [
            'click',
        ],
        props: {
            href: String,
            secondary: Boolean,
            positiveAction: Boolean,
            negativeAction: Boolean,
        },
    };
</script>
