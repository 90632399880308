<template>
    <div :class="{'': clear, 'mx-2 my-4 bg-gray-400 border-2 border-gray-500 shadow-md rounded-lg': inline, 'm-5 bg-form-body border-2 border-sbr-light-purple shadow-md rounded-lg': (!clear && !inline)}">
        <h2 :class="{'': clear, 'p-4 border-b-2 border-gray-500 font-semibold': inline, 'p-4 border-b-2 border-sbr-light-purple font-semibold': (!clear && !inline)}" v-if="title != '' && !clear">{{ title }}</h2>
        <div :class="{'': clear, 'p-4': inline, 'my-2 p-4': (!clear && !inline)}">
            <div class="w-full grid grid-cols-6 gap-6">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'form-section',
        props: {
            title: String,
            clear: Boolean,
            inline: Boolean,
        },
    };
</script>
