<template>
    <div class="w-1/6 flex flex-col pr-6">
        <label v-on:click="addBlock('speak')" class="palette-label">
            <i class="fas fa-user-check pr-3"></i> Speak
        </label>

        <label v-on:click="addBlock('play')" class="palette-label">
            <i class="far fa-comment pr-3"></i> Play
        </label>

        <label v-on:click="addBlock('dial')" class="palette-label">
            <i class="fas fa-bezier-curve pr-3"></i> Dial
        </label>

        <label v-on:click="addBlock('rep_lookup')" class="palette-label">
            <i class="far fa-paper-plane pr-3"></i> Rep Lookup
        </label>

        <label v-on:click="addBlock('hangup')" class="palette-label">
            <i class="fas fa-bezier-curve pr-3"></i> Hangup
        </label>

        <label v-on:click="addBlock('pause')" class="palette-label">
            <i class="fas fa-bezier-curve pr-3"></i> Pause
        </label>

        <label v-on:click="addBlock('collect_attribute')" class="palette-label">
            <i class="fas fa-user-tag pr-3"></i> Collect Attr
        </label>

        <label v-on:click="addBlock('tag_attribute')" class="palette-label">
            <i class="fas fa-user-tag pr-3"></i> Tag Attr
        </label>

        <label v-on:click="addBlock('add_to_segment')" class="palette-label">
            <i class="fas fa-user-tag pr-3"></i> Add to Segment
        </label>

        <label v-on:click="addBlock('menu')" class="palette-label">
            <i class="fas fa-user-plus pr-3"></i> Menu
        </label>

        <label v-on:click="addBlock('conditional')" class="palette-label">
            <i class="fas fa-tint pr-3"></i> Conditional
        </label>

        <label v-on:click="addBlock('notification')" class="palette-label">
            <i class="far fa-paper-plane pr-3"></i> Notification
        </label>
    </div>
</template>

<script>
    export default {
        name: 'Palette',
        components: {
        },
        data() {
            return {
                current_id: 0,
            }
        },
        methods: {
            addBlock(type) {
                let block = null

                if (type === 'speak') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'speak',
                        'params': {
                            'message': '',
                            'voice': 'alice',
                            'language': 'en',
                        },
                        'errors': [],
                    }
                } else if (type === 'dial') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'dial',
                        'params': {
                            'numbers': [],
                            'type': 'first',
                        },
                        'errors': [],
                    }
                } else if (type === 'play') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'play',
                        'params': {
                            'attachment_key': null,
                            'attachment_mimetype': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'hangup') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'hangup',
                        'params': {

                        },
                        'errors': [],
                    }
                } else if (type === 'pause') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'pause',
                        'params': {
                            'duration': 1,
                        },
                        'errors': [],
                    }
                } else if (type === 'menu') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'menu',
                        'params': {
                            'attribute_id': '',
                            'choices': [
                                {
                                    'key': '0',
                                    'name': 'Choice 1',
                                    'attribute_value': null,
                                    'blocks': [],
                                }
                            ],
                        },
                        'errors': [],
                    }
                } else if (type === 'add_to_segment') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'add_to_segment',
                        'params': {
                            'segment_id': '',
                        },
                        'errors': [],
                    }
                } else if (type === 'collect_attribute') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'collect_attribute',
                        'params': {
                            'request': {
                                'type': 'speak',
                                'message': '',
                                'voice': '',
                                'language': '',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                            'valid': {
                                'type': 'speak',
                                'message': '',
                                'voice': '',
                                'language': '',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                            'invalid': {
                                'type': 'speak',
                                'message': '',
                                'voice': '',
                                'language': '',
                                'attachment_key': null,
                                'attachment_mimetype': null,
                            },
                            'attribute_id': '',
                            'finish_on_key': '#',
                            'input': 'dtmf',
                            'profanity_filter': 'true',
                            'language': 'en-US',
                            'num_digits': '',
                            'timeout': 5,

                        },
                        'errors': [],
                    }
                } else if (type === 'conditional') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'conditional',
                        'params': {
                            'conditions': {
                                'operator': 'AND',
                                'conditions': []
                            },
                            'truthy': {
                                'messaging_type': 'conditional',
                                'blocks': [],
                            },
                            'falsy': {
                                'messaging_type': 'conditional',
                                'blocks': [],
                            },
                            'attribute_id': ''
                        },
                        'children': [
                            {
                                'id': this.current_id++,
                                'messaging_type': 'true',
                                'children': [],
                            },
                            {
                                'id': this.current_id++,
                                'messaging_type': 'false',
                                'children': [],
                            },
                        ],
                        'errors': [],
                    }
                } else if (type === 'tag_attribute') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'tag_attribute',
                        'params': {
                            'value': '',
                            'attribute_id': ''
                        },
                        'errors': [],
                    }
                } else if (type === 'notification') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'notification',
                        'params': {
                            'type': 'email',
                            'recipient': '',
                            'text': '',
                            'subject': '',
                            'url': '',
                        },
                        'errors': [],
                    }
                } else if (type === 'rep_lookup') {
                    block = {
                        'id': this.current_id++,
                        'messaging_type': 'rep_lookup',
                        'params': {
                            'match_by': 'first',
                            'filter_by': 'zip',
                            'level': 'federal',
                            'type': 'house',
                            'party': null,
                            'include_reps': [],
                            'exclude_reps': [],
                            'fallback_numbers': [],
                            'fallback_dial_type': 'first_answer',
                        },
                        'errors': [],
                    }
                }

                this.$emit('addblock', block)
            },
        },
        props: {
        }
    }
</script>
