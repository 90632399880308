<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Add Attribute</DialogTitle>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">Select an attribute below with a value to add to the person.</p>
                            </div>
                            <div class="mt-8">
                                <form-field>
                                    <form-select class="form-select" id="attribute" name="attribute" label="Attribute" v-model="add_attribute">
                                        <option :value="null">- Select Attribute -</option>
                                        <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute">{{ attribute.name }}</option>
                                    </form-select>
                                    <form-validation param="attribute_id" :errors="errors" />
                                </form-field>
                                <form-field v-if="add_attribute">
                                    <form-label for="add_attribute_value" label="Value"></form-label>
                                    <form-input id="add_attribute_value" name="add_attribute_value" v-model="add_attribute_value" v-if="add_attribute && add_attribute.values_type === 'open'"></form-input>
                                    <form-select id="add_attribute_value" name="add_attribute_value" v-model="add_attribute_value" v-if="add_attribute && add_attribute.values_type === 'predefined'">
                                        <option :value="null" disabled>- Select Value -</option>
                                        <option v-for="(value, value_index) in add_attribute.valid_values.values" v-bind:key="value_index" :value="value.value">{{ value.label || value.value }}</option>
                                    </form-select>
                                    <form-validation param="value" :errors="errors" />
                                </form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="$emit('add')">
                            {{ actionText }}
                        </form-button>
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'
    import FormSelect from '../../shared/form/Select'
    import FormInput from '../../shared/form/Input'
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'add-attribute-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormButton,
            FormField,
            FormLabel,
            FormSelect,
            FormInput,
            FormValidation,
        },
        emits: [
            'add',
            'cancel',
            'update:addAttribute',
            'update:addAttributeValue',
        ],
        props: {
            name: String,
            attributes: Array,
            addAttribute: Object,
            addAttributeValue: String,
            errors: Object,
            actionText: {
                type: String,
                default: 'Add',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            add_attribute: {
                get() {
                    return this.addAttribute
                },
                set(val) {
                    this.$emit('update:addAttribute', val);
                }
            },
            add_attribute_value: {
                get() {
                    return this.addAttributeValue
                },
                set(val) {
                    this.$emit('update:addAttributeValue', val);
                }
            },
        },
    };
</script>
