<template>
    <div class="m-5">
        <div class="my-6 rounded-lg border-2 border-gray-200 bg-gray-100 shadow-sm flex flex-col sm:flex-row">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'filter-filter',
        props: {
            cols: {
                type: Number,
            }
        },
    };
</script>
