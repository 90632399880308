<template>
    <div>
        <layout-header title="Create New Report"></layout-header>

        <form-section title="Report Details">
            <form-field wide>
                <p class="text-red-500 text-xs italic" v-if="errors && errors.self">{{ errors.self[0] }}</p>
            </form-field>

            <form-field>
                <form-input id="name" name="name" label="Name" v-model="report.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="type" name="type" label="Type" v-model="report.type">
                    <option :value="null">- Select Report Type -</option>
                    <option value="call_summary">Call Summary</option>
                    <option value="rep_summary">Call Rep Summary</option>
                    <option value="call_export">Call Export</option>
                    <option value="segment_person_export">Segment Person Export</option>
                </form-select>
                <form-validation param="type" :errors="errors" />
            </form-field>

            <form-field v-if="showNumberSelect()">
                <form-label for="numbers" label="Number"></form-label>
                <multiselect v-model="report.params.numbers" :options="numbers" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" :custom-label="friendlyNumberName" placeholder="Select a Number"></multiselect>
                <form-validation param="params.number_ids" :errors="errors" />
            </form-field>
            <form-field v-if="showSegmentSelect('static')">
                <form-label for="static_segments" label="Static Segments"></form-label>
                <multiselect id="static_segments" v-model="report.params.segments" :options="static_segments" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" :label="name" placeholder="Select a Segment"></multiselect>
                <form-validation param="params.segment_ids" :errors="errors" />
            </form-field>
            <form-field v-if="showSegmentSelect('all')">
                <form-label for="segments" label="Segments"></form-label>
                <multiselect id="segments" v-model="report.params.segments" :options="segments" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Select a Segment"></multiselect>
                <form-validation param="params.segment_ids" :errors="errors" />
            </form-field>
            <form-field v-if="showSequenceSelect()">
                <form-label for="sequences" label="Sequences"></form-label>
                <multiselect v-model="report.params.sequences" :options="sequences" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Select a Sequence"></multiselect>
                <form-validation param="params.sequence_ids" :errors="errors" />
            </form-field>
            <form-field>
                <form-label for="date_range">Date Range</form-label>
                <form-datepicker id="date_range" name="date_range" label="Date Range" v-model="report.params.date_range" type="daterange" :range="true"></form-datepicker>
                <form-validation param="params.date_start" :errors="errors" />
            </form-field>
            <form-field>
                <form-select id="group_by" label="Group By" v-model="report.params.group_by">
                    <option :value="null">- Select Group By -</option>
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                    <option value="year">Year</option>
                </form-select>
                <form-validation param="params.group_by" :errors="errors" />
            </form-field>
            <form-field v-if="showDirection()">
                <form-select id="direction" label="Direction" v-model="report.params.direction">
                    <option :value="null">All</option>
                    <option value="in">In</option>
                    <option value="out">Out</option>
                </form-select>
                <form-validation param="params.direction" :errors="errors" />
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Create Report
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import FormDatepicker from '../../shared/form/DatePicker';

    export default {
        name: 'CreateReport',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            FormDatepicker,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetchNumbers()
            this.fetchSegments()
            this.fetchSequences()
        },
        watch: {
            'report.params.date_range' (newDate, oldDate) {
                this.closeDatepicker();
            }
        },
        data() {
            return {
                numbers: [],
                segments: [],
                static_segments: [],
                sequences: [],
                report: {
                  name: '',
                  number_id: null,
                  type: null,
                  params: {
                      date_range: '',
                      group_by: null,
                      numbers: [],
                      direction: null,
                  }
                },
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            friendlyNumberName({name, number}) {
                return `${name} — (${number})`
            },
            closeDatepicker() {
                this.$refs.datepicker.closePopup()
            },
            fetchNumbers() {
                this.$http.get(`/api/v1/numbers?per_page=-1`).then(this.refreshNumbers);
            },
            refreshNumbers({data}) {
                this.numbers = data.data;
            },
            updateType() {
                this.report.params.sequence_id = null
                this.report.params.segments = []
            },
            fetchSegments() {
                this.$http.get(`/api/v1/segments?per_page=-1&search_types=static`).then(this.refreshSegments);
            },
            refreshSegments({ data }) {
                this.segments = data.data;

                data.data.forEach(item => {
                    if (item.type === 'static') {
                        this.static_segments.push(item)
                    }
                })
            },
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({ data }) {
                this.sequences = data.data;
            },
            save() {
              let report = this.buildReport()
              this.$http.post('/api/v1/reports', report).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The report has been created',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_reports'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while creating the report',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            showGroupBy() {
                if (this.report.type === 'call_summary' || this.report.type === 'rep_summary')
                    return true
                else
                    return false
            },
            showDirection() {
                if (this.report.type === 'call_export')
                    return true
                else
                    return false
            },
            showNumberSelect() {
                if (this.report.type === 'call_summary' || this.report.type === 'rep_summary' || this.report.type === 'call_export')
                    return true
                else
                    return false
            },
             showSegmentSelect(type) {
                if (type === 'static') {
                    return false
                } else {
                    if (this.report.type === 'segment_person_export')
                        return true
                    else
                        return false
                }
            },
            showSequenceSelect() {
                if (this.report.type === 'recording_export')
                    return true
                else
                    return false
            },
            buildReport() {
                let report = {
                    name: this.report.name,
                    number_id: null,
                    type: this.report.type,
                    params: {
                        date_start: this.$moment(this.report.params.date_range[0]).format('YYYY-MM-DD'),
                        date_end: this.$moment(this.report.params.date_range[1]).format('YYYY-MM-DD'),
                    }
                }

                for (let i in this.report.params.numbers) {
                    report.params.number_ids.push(this.report.params.numbers[i].id)
                }

                if (this.report.type === 'call_summary' || this.report.type === 'rep_summary') {
                    report.params.group_by = this.report.params.group_by
                }

                if (this.report.type === 'call_export') {
                    report.params.direction = this.report.params.direction
                }

                if (this.report.type !== 'segment_person_export') {
                    report.params.number_ids = []
                }

                if (this.report.type === 'segment_person_export') {
                    report.params.segment_ids = []
                    report.params.attribute_ids = []

                    for (let i in this.report.params.segments) {
                        report.params.segment_ids.push(this.report.params.segments[i].id)
                    }
                }

                if (this.report.type === 'recording_export') {
                    report.params.sequence_ids = []
                }

                /*if (this.report.type === 'message_summary' || this.report.type === 'message_export') {
                    report.params.sequence_id = this.report.params.sequence_id
                }*/

                return report
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
