import Vuex from 'vuex';
import axios from 'axios';

export default new Vuex.Store({
    state: {
        current_number_id: (localStorage.getItem('current_number_id') ? parseInt(localStorage.getItem('current_number_id')) : null),
        status: '',
        token: window.$cookies.get('token') || '', //localStorage.getItem('token') || '',
        user: {}
    },
    mutations: {
        updateNumberID(state, number_id) {
            state.current_number_id = number_id;
            localStorage.setItem('current_number_id', number_id);
        },
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, user) {
            state.status = 'success'
            state.token = token
            state.user = user
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
    },
    actions: {
        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit('logout')
                delete axios.defaults.headers.common['Authorization']
                window.$cookies.remove('token', '/', process.env.MIX_ROOT_APP_URL)
                resolve()
            })
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        current_number_id: state => state.current_number_id,
    }
})
