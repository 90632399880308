require('./bootstrap');

import { createApp } from 'vue';
import Notifications from '@kyvg/vue3-notification';
import Axios from 'axios';
import moment from 'moment-timezone';
import Paginate from 'vuejs-paginate-next';
import Multiselect from 'vue-multiselect';
import Chartkick from 'vue-chartkick';
import Chart from 'chart.js';
import VueCookies from 'vue-cookies';
import VueNestable from 'vue-nestable';
import VuePapaParse from 'vue-papa-parse';
import VSwatches from 'vue3-swatches';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import mitt from 'mitt';

import Main from './components/Main';
import router from './routes';
import store from './store';
import { capitalize, formatDate, formatDateSeconds, formatSeconds, formatMinutes } from "@/filters/strings";

moment.tz.setDefault('UTC');

Bugsnag.start({
    apiKey: process.env.MIX_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
});

const emitter = mitt();
const app = createApp(Main);

app.use(Bugsnag.getPlugin('vue'));
app.use(Notifications);
app.use(Chartkick.use(Chart));
//app.use(VueCookies);
app.use(VueNestable);
app.use(VuePapaParse);
app.use(router);
app.use(store);
app.use(VSwatches);
app.component('paginate', Paginate);
app.component('multiselect', Multiselect);

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$http = Axios;
app.config.globalProperties.$http.defaults.headers.common['Content-Type'] = 'application/json';
app.config.globalProperties.$http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
app.config.globalProperties.$http.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

if(window.$cookies.get('token')) {
    app.config.globalProperties.$http.defaults.headers.common['Authorization'] = 'Bearer '+window.$cookies.get('token')
}

// Filters
app.config.globalProperties.$filters = {
    capitalize: capitalize,
    formatDate: formatDate,
    formatDateSeconds: formatDateSeconds,
    formatSeconds: formatSeconds,
    formatMinutes: formatMinutes,
};

app.mount('#app');
