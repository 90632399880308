<template>
    <div class="min-h-screen flex flex-col">
    <!-- Header Navigation -->
    <nav class="bg-sbr-purple">
        <!-- Header (Larger Only) -->
        <div class="max-w-7xl mx-auto px-2">
            <div class="flex items-center justify-between h-16">
                <!-- Logo -->
                <div class="flex items-center pl-2">
                    <div class="flex-shrink-0">
                        <a :href="portal_url">
                            <img class="h-8" src="/img/SBR_horiz_med_light.png" />
                        </a>
                    </div>
                </div>

                <div class="items-center md:w-96 justify-center md:justify-start text-white px-2" >

                </div>

                <!-- Right Menu Links (Larger Only) -->
                <div class="hidden md:block">
                    <div class="ml-4 flex items-center">
                        <div class="ml-3 hidden md:block">
                            <div class="flex items-baseline space-x-4">
                                <header-link :href="help_url" target="_blank">Help</header-link>
                                <header-link :href="support_url">Support</header-link>
                            </div>
                        </div>

                        <!-- Profile dropdown -->
                        <div class="ml-3 relative">
                            <div>
                                <button type="button" @click="toggleUserDropdown()" class="font-semibold p-2 text-gray-300 hover:text-white max-w-xs flex items-center text-sm" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                    Hi, {{user.first_name}}
                                    <svg class="h-3 fill-current inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                    </svg>
                                </button>
                            </div>

                            <!--
                                Dropdown menu, show/hide based on menu state.

                                Entering: "transition ease-out duration-100"
                                From: "transform opacity-0 scale-95"
                                To: "transform opacity-100 scale-100"
                                Leaving: "transition ease-in duration-75"
                                From: "transform opacity-100 scale-100"
                                To: "transform opacity-0 scale-95"
                            -->
                            <div id="user_dropdown" class="z-30 origin-top-right absolute right-0 mt-4 w-48 rounded-md shadow-lg bg-white invisible" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                                <!-- Active: "bg-gray-100", Not Active: "" -->
                                <header-dropdown-link @click="toggleUserDropdown()" :href="portal_url + '/settings/profile'"><i class="fa fa-user fa-fw"></i> Profile</header-dropdown-link>
                                <header-dropdown-link @click="toggleUserDropdown()" :href="portal_url + '/settings/account'" v-if="user.account_level === 'admin'"><i class="fa fa-cog fa-fw"></i> Settings</header-dropdown-link>
                                <div class="border border-grey-darkest"></div>
                                <header-dropdown-link @click="releaseUser()" v-if="is_impersonated"><i class="fas fa-sign-out-alt fa-fw"></i> Release User</header-dropdown-link>
                                <header-dropdown-link @click="logoutUser()"><i class="fas fa-sign-out-alt fa-fw"></i> Log Out</header-dropdown-link>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Mobile Menu Button (Mobile Only) -->
                <div class="-mr-2 flex md:hidden">
                    <button type="button" @click="toggleMobileMenu()" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white" aria-controls="mobile-menu" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <svg id="mobile_menu_icon_closed" class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <svg id="mobile_menu_icon_open" class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Mobile Menu (Mobile Only) -->
        <div class="hidden md:hidden" id="mobile_menu">
            <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                <header-mobile-link href="/" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-home fa-fw"></i> Home</header-mobile-link>
                <header-mobile-link href="/numbers" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-phone fa-fw"></i> Numbers</header-mobile-link>
                <header-mobile-link href="/attributes" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-database fa-fw"></i> Attributes</header-mobile-link>
                <header-mobile-link href="/diallists" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-list-ol fa-fw" v-if="user.settings.talk.features.dial_lists_manage"></i> Dial Lists</header-mobile-link>
                <header-mobile-link href="/segments" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-list fa-fw"></i> Segments</header-mobile-link>
                <header-mobile-link href="/sequences" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-sort-amount-down fa-fw"></i> Sequences</header-mobile-link>
                <header-mobile-link href="/webforms" type="router" @click="toggleMobileMenu()"><i class="mr-2 fab fa-wpforms fa-fw"></i> Webforms</header-mobile-link>
                <header-mobile-link href="/reports" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-chart-bar fa-fw"></i> Reports</header-mobile-link>
                <header-mobile-link href="/people" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-user-check fa-fw"></i> People</header-mobile-link>
                <header-mobile-link href="/calls" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-phone-alt fa-fw"></i> Calls</header-mobile-link>
                <header-mobile-link href="/recordings" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-microphone fa-fw"></i> Recordings</header-mobile-link>
            </div>
            <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3 border-t-2 border-white">
                <header-mobile-link :href="help_url" target="_blank" @click="toggleMobileMenu()"><i class="mr-2 fas fa-question-circle fa-fw"></i> Help</header-mobile-link>
                <header-mobile-link :href="support_url" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-life-ring fa-fw"></i> Support</header-mobile-link>
                <header-mobile-link :href="portal_url + '/settings/profile'" type="router" @click="toggleMobileMenu()"><i class="mr-2 fa fa-user fa-fw"></i> Profile</header-mobile-link>
            </div>
            <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3 border-t-2 border-white">
                <header-mobile-link @click="logoutUser()"><i class="mr-2 fas fa-sign-out-alt fa-fw"></i> Log Out</header-mobile-link>
            </div>
        </div>
    </nav>

    <!-- Main Content -->
    <div class="flex flex-col md:flex-row min-h-screen">
        <!-- Left Navigation (Larger Only) -->
        <div class="bg-gray-100 text-black bottom-0 md:relative hidden md:block md:w-48 grid grid-cols-1 divide-y px-2 border-r-2 border-gray-200">
            <div class="py-4">
                <navigation-link href="/" type="router" exact><i class="py-1 mr-2 fas fa-home fa-fw float-right"></i> Home</navigation-link>
                <navigation-link href="/numbers" type="router"><i class="py-1 mr-2 fas fa-phone fa-fw float-right"></i> Numbers</navigation-link>
                <navigation-link href="/attributes" type="router"><i class="py-1 mr-2 fas fa-database fa-fw float-right"></i> Attributes</navigation-link>
                <navigation-link href="/diallists" type="router"><i class="py-1 mr-2 fas fa-list-ol fa-fw float-right" v-if="user.settings.talk.features.dial_lists_manage"></i> Dial Lists</navigation-link>
                <navigation-link href="/segments" type="router" exact><i class="py-1 mr-2 fas fa-list fa-fw float-right"></i> Segments</navigation-link>
                <navigation-link href="/sequences" type="router" exact><i class="py-1 mr-2 fas fa-sort-amount-down fa-fw float-right"></i> Sequences</navigation-link>
                <navigation-link href="/webforms" type="router" exact><i class="py-1 mr-2 fab fa-wpforms fa-fw float-right"></i> Webforms</navigation-link>
                <navigation-link href="/reports" type="router"><i class="py-1 mr-2 fas fa-chart-bar fa-fw float-right"></i> Reports</navigation-link>
                <navigation-link href="/people" type="router" exact><i class="py-1 mr-2 fas fa-user-check fa-fw float-right"></i> People</navigation-link>
                <navigation-link href="/calls" type="router" exact><i class="py-1 mr-2 fas fa-phone-alt fa-fw float-right"></i> Calls</navigation-link>
                <navigation-link href="/recordings" type="router" exact><i class="py-1 mr-2 fas fa-microphone fa-fw float-right"></i> Recordings</navigation-link>
            </div>
        </div>

        <!-- Body Content -->
        <div class="main-content flex-1 pt-5 px-4">
            <slot></slot>

            <footer class="text-white pb-4 pt-24">
                <div class="max-w-7xl mx-auto pt-4 px-4 overflow-hidden sm:px-6 lg:px-8">
                    <div class="mt-4 flex justify-center space-x-6 text-lg">
                        <a :href="facebook_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">Facebook</span> <i class="fab fa-facebook-square"></i></a>
                        <a :href="github_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">Github</span> <i class="fab fa-github-square"></i></a>
                        <a :href="twitter_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">Twitter</span> <i class="fab fa-twitter-square"></i></a>
                        <a :href="instagram_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">Instagram</span> <i class="fab fa-instagram-square"></i></a>
                        <a :href="linkedin_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">LinkedIn</span> <i class="fab fa-linkedin"></i></a>
                        <a :href="site_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">Website</span> <i class="fas fa-globe"></i></a>
                    </div>
                    <div class="mt-4 flex justify-center space-x-6">
                        <a :href="privacy_url" target="_blank" class="text-gray-600 hover:text-gray-500">Privacy Policy</a>
                        <a :href="terms_url" target="_blank" class="text-gray-600 hover:text-gray-500">Terms</a>
                    </div>
                    <p class="mt-8 text-center text-sm text-gray-600">
                        &copy; {{ current_year }} SeeBotRun, LLC. All rights reserved.
                    </p>
                </div>
            </footer>
        </div>
    </div>
  </div>
</template>

<script>
    import HeaderLink from './header/HeaderLink'
    import HeaderDropdownLink from './header/HeaderDropdownLink'
    import HeaderMobileLink from './header/HeaderMobileLink'
    import NavigationLink from './navigation/NavigationLink'

    export default {
        name: 'layout-app',
        components: {
            HeaderLink,
            HeaderDropdownLink,
            HeaderMobileLink,
            NavigationLink,
        },
        created() {
            this.fetchUser();
        },
        computed: {
            is_impersonated: function() {
                return this.$store.getters.isImpersonated;
            },
            current_user: function() {
                return this.$store.getters.user;
            },
        },
        mounted() {
            this.$emitter.on('numbers_updated', () => {
                //this.fetchNumbers();
            });
        },
        data() {
            return {
                numbers: [],
                user: {
                    id: null,
                    first_name: null,
                    last_name: null,
                    settings: {
                        talk: {
                            features: {
                                dial_lists_manage: false,
                                dial_lists: false,
                                record: false,
                                reports: false,
                            }
                        }
                    }
                },
                portal_url: process.env.MIX_PORTAL_URL,
                links_url: process.env.MIX_LINKS_APP_URL,
                chat_url: process.env.MIX_CHAT_APP_URL,
                text_url: process.env.MIX_TEXT_APP_URL,
                talk_url: process.env.MIX_TALK_APP_URL,
                help_url: process.env.MIX_HELP_URL,
                support_url: process.env.MIX_SUPPORT_URL,
                facebook_url: process.env.MIX_FACEBOOK_URL,
                twitter_url: process.env.MIX_TWITTER_URL,
                instagram_url: process.env.MIX_INSTAGRAM_URL,
                github_url: process.env.MIX_GITHUB_URL,
                linkedin_url: process.env.MIX_LINKEDIN_URL,
                site_url: process.env.MIX_SITE_URL,
                privacy_url: process.env.MIX_PRIVACY_URL,
                terms_url: process.env.MIX_TERMS_URL,
                current_year: new Date().getFullYear(),
            }
        },
        methods: {
            fetchUser() {
                this.$http.get(`/api/v1/me`).then(this.refreshUser);
            },
            refreshUser({data}) {
                this.user = data.data;
            },
            toggleUserDropdown() {
                document.getElementById('user_dropdown').classList.toggle("invisible");
            },
            logoutUser() {
                this.$store.dispatch('logout')
                    .then(() => {
                        window.location = process.env.MIX_PORTAL_LOGIN_URL
                    })
                    .catch(err => console.log(err))
            },
            toggleUserDropdown() {
                let id = 'user_dropdown'
                document.getElementById(id).classList.toggle('invisible');
            },
            toggleMobileMenu() {
                let id = 'mobile_menu'
                document.getElementById(id).classList.toggle('hidden')
                document.getElementById(id+'_icon_closed').classList.toggle('hidden')
                document.getElementById(id+'_icon_open').classList.toggle('hidden')
            },
        }
    }
</script>
