<template>
    <div class="m-5">
        <div class="md:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-sbr-orange focus:ring-sbr-orange" @change="$emit('change', $event.target.value) ">
                <option v-for="(tab, index) in tabs" :key="index" :selected="tab.current" :value="index">{{ tab.name }}</option>
            </select>
        </div>
        <div class="hidden md:block">
            <div class="border-b-2 border-gray-200">
                <nav class="mx-4 flex space-x-8" aria-label="Tabs">
                    <a v-for="(tab, index) in tabs" :key="index" :href="tab.href" @click="$emit('change', index)" :class="[tab.current ? 'border-sbr-orange text-sbr-orange' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined">
                        <component :is="tab.icon" :class="[tab.current ? 'text-sbr-orange' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                        <span>{{ tab.name }}</span>
                    </a>
                </nav>
            </div>
        </div>

        <slot></slot>
    </div>
</template>

<script>

    export default {
        name: 'layout-tabs',
        props: {
            tabs: {
                type: Array,
            }
        },
        emits: [
            'update',
        ],
    };
</script>
