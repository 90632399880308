<template>
    <div>
        <layout-header title="Edit Attribute"></layout-header>

        <form-section title="Attribute Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="attribute.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="attribute.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="type" name="type" label="Type" v-model="attribute.type">
                    <option value="string">String</option>
                    <option value="alpha_only">String (alpha only)</option>
                    <option value="numeric_only">String (numeric only)</option>
                    <option value="alphanumeric_only">String (alphanumeric only)</option>
                    <option value="email">Email</option>
                    <option value="phone">Phone</option>
                    <option value="zip">ZIP</option>
                    <option value="zip_plusfour">ZIP + 4</option>
                    <option value="boolean">Boolean (yes/no)</option>
                </form-select>
                <form-validation param="type" :errors="errors" />
            </form-field>

            <form-field>
                <form-input id="length" name="length" label="Length" v-model="attribute.length" :disabled="disable_length"></form-input>
                <form-validation param="length" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="values_type" name="values_type" label="Values" v-model="attribute.values_type" :disabled="disable_values_type" @change="updateValuesType()">
                    <option value="open">Open</option>
                    <option value="predefined">Predefined</option>
                </form-select>
                <form-validation param="values_type" :errors="errors" />
            </form-field>
        </form-section>

        <form-section title="Predefined Values" v-if="attribute.values_type === 'predefined'">
            <form-field wide>
                <form-validation param="valid_values" :errors="errors" />
                <form-validation param="valid_values.values" :errors="errors" />
            </form-field>

            <!-- Row 1 -->
            <form-field wide>
                <div class="border-solid border-2 border-gray-300 bg-gray-100 mb-4 mr-4 p-4">
                        <div class="flex flex-wrap -mx-3 mb-6" v-if="attribute.valid_values.values.length > 0">
                            <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                                <form-label for="valid_values_0_value" label="Value"></form-label>
                            </div>
                            <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                                <form-label for="valid_values_0_label" label="Label"></form-label>
                            </div>
                            <div class="w-full md:w-1/5 cursor-pointer h-full pl-6 text-left"></div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-4" v-for="(value, index) in attribute.valid_values.values" v-bind:key="index">
                            <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                                <form-input :id="'valid_values_'+index+'_value'" :name="'valid_values_'+index+'_value'" label="" v-model="attribute.valid_values.values[index].value"></form-input>
                                <form-validation :param="'valid_values.values.'+index+'.value'" :errors="errors" />
                            </div>
                            <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                                <form-input :id="'valid_values_'+index+'_label'" :name="'valid_values_'+index+'_label'" v-model="attribute.valid_values.values[index].label"></form-input>
                                <form-validation :param="'valid_values.values.'+index+'.label'" :errors="errors" />
                            </div>
                            <div class="w-full md:w-1/5 cursor-pointer h-full pl-6 text-left">
                                <form-button negative-action @click="removeValidValue(index)"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                            </div>
                        </div>

                        <div class="flex flex-wrap mx-3 mb-6">
                            <form-button positive-action @click="addValidValue()"><i class="fas fa-plus-circle"></i> ADD</form-button>
                        </div>
                </div>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Update Attribute
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import SearchNumberModal from '../../shared/modal/SearchNumberModal'

    export default {
        name: 'EditAttribute',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            SearchNumberModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetchAttribute();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                attribute: {
                  name: '',
                  type: 'string',
                  length: 0,
                  values_type: 'open',
                  valid_values: {
                      values: []
                  },
                  status: 'active',
                  number_id: null,
                },
                disable_values_type: true,
                disable_length: true,
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            fetchAttribute() {
                this.$http.get(`/api/v1/attributes/${this.id}`).then(this.refreshAttribute);
            },
            refreshAttribute({data}) {
                this.attribute = data.data;
                this.updateType();
            },
            save() {
                this.$http.put('/api/v1/attributes/'+this.id, this.attribute).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The attribute has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_attributes'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred updating the attribute',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addValidValue() {
                this.attribute.valid_values.values.push({
                    value: '',
                    label: '',
                });
            },
            removeValidValue(index) {
                this.attribute.valid_values.values.splice(index, 1);
            },
            updateType() {
                if (this.attribute.type === 'email' || this.attribute.type === 'phone' || this.attribute.type === 'zip' || this.attribute.type === 'zip_plusfour' || this.attribute.type === 'boolean') {
                    this.attribute.values_type = 'open';
                    this.attribute.valid_values = {values: []};
                    this.disable_values_type = true;
                    this.disable_length = false;
                } else {
                    this.disable_values_type = false;
                    this.disable_length = false;
                }

                if (this.attribute.type === 'zip') {
                    this.attribute.length = 5;
                    this.disable_length = true;
                }

                if (this.attribute.type === 'zip_plusfour') {
                    this.attribute.length = 4;
                    this.disable_length = true;
                }

                if (this.attribute.type === 'boolean') {
                    this.attribute.length = 3;
                    this.disable_length = true;
                }
            },
            updateValuesType() {
                if (this.attribute.valid_values === null) {
                    this.attribute.valid_values = {
                        values: [],
                    };
                }
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
