<template>
    <div>
        <layout-header :title="'Viewing '+person.phone_number">
        </layout-header>

        <layout-tabs :tabs="tabs" @change="(index) => {
            tabs.forEach((tab, index) => {
                tabs[index].current = false;
            });
            tabs[index].current = true;
        }">
            <div v-if="tabs[0].current">
                <div class="text-right m-2">
                    <form-button @click="triggerSequenceDialog()">Trigger Sequence</form-button>
                </div>

                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header @click="updateCallSort('direction')" hide-sm>In/Out</table-header>
                            <table-header @click="updateCallSort('recipient_number')">Call To</table-header>
                            <table-header @click="updateCallSort('duration')" hide-md>Call Duration</table-header>
                            <table-header @click="updateCallSort('status')" hide-md>Call Status</table-header>
                            <table-header @click="updateCallSort('created_at')" last>Started At</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(call, index) in calls" :key="call.id" :index="index">
                            <table-cell bold hide-sm>{{ $filters.capitalize(call.direction) }}</table-cell>
                            <table-cell>{{ call.recipient_number }}</table-cell>
                            <table-cell hide-md>{{ $filters.formatMinutes(call.duration) }}</table-cell>
                            <table-cell hide-md>{{ $filters.capitalize(call.status) }}</table-cell>
                            <table-cell last>{{ $filters.formatDate(call.created_at) }}</table-cell>
                        </table-row>
                    </template>
                </table-table>

                <paginate
                    :page-count="call_pages"
                    :click-handler="fetchCalls"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'pagination-page'"
                    :prev-class="'pagination-prev'"
                    :next-class="'pagination-next'"
                    :disabled-class="'pagination-inactive'">
                </paginate>
            </div>

            <div v-if="tabs[1].current">
                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header hide-sm>Name</table-header>
                            <table-header>Number</table-header>
                            <table-header>Source</table-header>
                            <table-header last>Created At</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(number, index) in person.numbers" :key="number.id" :index="index">
                            <table-cell hide-sm bold>{{ $filters.capitalize(number.name) }}</table-cell>
                            <table-cell>{{ number.number }}</table-cell>
                            <table-cell>{{ $filters.capitalize(number.pivot.source) }}</table-cell>
                            <table-cell last>{{ $filters.formatDate(number.pivot.added_at) }}</table-cell>
                        </table-row>
                    </template>
                </table-table>
            </div>

            <div v-if="tabs[2].current">
                <div class="text-right m-2">
                    <form-button @click="addToSegmentDialog()">Add to Segment</form-button>
                </div>

                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header hide-sm>Name</table-header>
                            <table-header>Created At</table-header>
                            <table-header>Removed At</table-header>
                            <table-header last>Actions</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(segment, index) in person.segments" :key="segment.id" :index="index">
                            <table-cell hide-sm bold>{{ $filters.capitalize(segment.name) }}</table-cell>
                            <table-cell>{{ $filters.formatDate(segment.pivot.added_at) }}</table-cell>
                            <table-cell>{{ $filters.formatDate(segment.pivot.removed_at) }}</table-cell>
                            <table-cell last><i class="fas fa-trash" @click="confirmRemoveFromSegment(segment.id)"></i></table-cell>
                        </table-row>
                    </template>
                </table-table>
            </div>

            <div v-if="tabs[3].current">
                <div class="text-right m-2">
                    <form-button @click="addAttributeDialog()">Add Attribute</form-button>
                </div>

                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header hide-sm>Name</table-header>
                            <table-header>Value</table-header>
                            <table-header>Created At</table-header>
                            <table-header>Updated At</table-header>
                            <table-header last>Actions</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(attribute, index) in person.attributes" :key="attribute.id" :index="index">
                            <table-cell hide-sm bold>{{ $filters.capitalize(attribute.name) }}</table-cell>
                            <table-cell>{{ attribute.pivot.value }}</table-cell>
                            <table-cell>{{ $filters.formatDate(attribute.pivot.created_at) }}</table-cell>
                            <table-cell>{{ $filters.formatDate(attribute.pivot.updated_at) }}</table-cell>
                            <table-cell last><i class="fas fa-trash" v-if="attribute.scope !== 'system'" @click="confirmRemoveAttribute(attribute.id)"></i></table-cell>
                        </table-row>
                    </template>
                </table-table>
            </div>

            <div v-if="tabs[4].current">
                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header hide-sm>Webform</table-header>
                            <table-header>IP Address</table-header>
                            <table-header last>Created At</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(submission, index) in person.webform_submissions" :key="submission.id" :index="index">
                            <table-cell hide-sm bold>{{ submission.webform.name }}</table-cell>
                            <table-cell>{{ $filters.capitalize(submission.ip_address) }}</table-cell>
                            <table-cell last>{{ $filters.formatDate(submission.created_at) }}</table-cell>
                        </table-row>
                    </template>
                </table-table>
            </div>

            <div v-if="tabs[5].current">
                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header hide-sm>Sequence</table-header>
                            <table-header>Status</table-header>
                            <table-header>Recording</table-header>
                            <table-header>Created At</table-header>
                            <table-header last>Actions</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(recording, index) in person.recordings" :key="recording.id" :index="index">
                            <table-cell hide-sm bold>{{ recording.sequence.name }}</table-cell>
                            <table-cell>{{ $filters.capitalize(recording.recording_status) }}</table-cell>
                            <table-cell><audio controls :src="'/recordings/'+recording.id+'/download'" style="max-width: 200px;"></audio></table-cell>
                            <table-cell>{{ $filters.formatDate(recording.created_at) }}</table-cell>
                            <table-cell last><i class="fas fa-trash" @click="confirmRemoveRecording(recording.id)"></i></table-cell>
                        </table-row>
                    </template>
                </table-table>
            </div>
        </layout-tabs>

        <ConfirmModal
            name="delete-confirm"
            title="Remove Attribute?"
            description="Are you sure you want to remove this attribute?  This action cannot be undone."
            actionText="Remove"
            :show="open_remove_attribute_modal"
            @confirm="removeAttribute()"
            @cancel="open_remove_attribute_modal = false"
        />

        <ConfirmModal
            name="delete-confirm"
            title="Remove Segment?"
            description="Are you sure you want to remove this segment?  This action cannot be undone."
            actionText="Remove"
            :show="open_remove_segment_modal"
            @confirm="removeSegment()"
            @cancel="open_remove_segment_modal = false"
        />

        <ConfirmModal
            name="delete-confirm"
            title="Delete Recording?"
            description="Are you sure you want to delete this recording?  This action cannot be undone."
            actionText="Delete"
            :show="open_remove_recording_modal"
            @confirm="removeRecording()"
            @cancel="open_remove_recording_modal = false"
        />

        <TriggerSequenceModal
            name="trigger-sequence"
            actionText="Trigger"
            :sequences="sequences"
            v-model:triggerSequenceID="trigger_sequence_id"
            :errors="trigger_sequence_errors"
            :show="open_trigger_sequence_modal"
            @trigger="triggerSequence()"
            @cancel="open_trigger_sequence_modal = false"
        />

        <AddSegmentModal
            name="add-segment"
            actionText="Add"
            :segments="segments"
            v-model:addSegmentID="add_segment_id"
            :errors="segment_errors"
            :show="open_add_segment_modal"
            @add="addSegment()"
            @cancel="open_add_segment_modal = false"
        />

        <AddAttributeModal
            name="add-attribute"
            actionText="Add"
            :attributes="attributes"
            v-model:addAttribute="add_attribute"
            v-model:addAttributeValue="add_attribute_value"
            :errors="attribute_errors"
            :show="open_add_attribute_modal"
            @add="addAttribute()"
            @cancel="open_add_attribute_modal = false"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import LayoutButton from '../../shared/layout/Button';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import FormDatepicker from '../../shared/form/DatePicker';
    import LayoutSummary from '../../shared/layout/Summary';
    import LayoutSummaryItem from '../../shared/layout/SummaryItem';
    import LayoutTabs from '../../shared/layout/Tabs';
    import TableTable from '../../shared/table/Table'
    import TableRow from '../../shared/table/Row'
    import TableHeader from '../../shared/table/Header'
    import TableCell from '../../shared/table/Cell'
    import ConfirmModal from '../../shared/modal/ConfirmModal'
    import TriggerSequenceModal from '../../shared/modal/TriggerSequenceModal'
    import AddSegmentModal from '../../shared/modal/AddSegmentModal'
    import AddAttributeModal from '../../shared/modal/AddAttributeModal'
    import { PhoneIcon, PhoneArrowDownLeftIcon, ListBulletIcon, CircleStackIcon, QueueListIcon, MicrophoneIcon } from '@heroicons/vue/20/solid'

    export default {
        name: 'ViewPerson',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            FormDatepicker,
            LayoutSummary,
            LayoutSummaryItem,
            LayoutTabs,
            ConfirmModal,
            TriggerSequenceModal,
            AddSegmentModal,
            AddAttributeModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetch();
            this.fetchCalls(1);
            this.fetchSegments();
            this.fetchSequences();
            this.fetchAttributes();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                person: {
                    id: '',
                    first_name: '',
                    last_name: '',
                    added_at: '',
                    source: '',
                    segments: [],
                    attributes: [],
                    recordings: [],
                    webform_submissions: [],
                },
                calls: [],
                call_pages: 1,
                attributes: [],
                segments: [],
                sequences: [],
                add_segment_id: null,
                add_attribute: null,
                add_attribute_value: '',
                remove_segment_id: null,
                remove_attribute_id: null,
                remove_recording_id: null,
                trigger_sequence_id: null,
                attribute_errors: [],
                segment_errors: [],
                trigger_sequence_errors: [],
                tabs: [
                    { name: 'Calls', href: '#', icon: PhoneIcon, current: true },
                    { name: 'Numbers', href: '#', icon: PhoneArrowDownLeftIcon, current: false },
                    { name: 'Segments', href: '#', icon: ListBulletIcon, current: false },
                    { name: 'Attributes', href: '#', icon: CircleStackIcon, current: false },
                    { name: 'Submissions', href: '#', icon: QueueListIcon, current: false },
                    { name: 'Recordings', href: '#', icon: MicrophoneIcon, current: false },
                ],
                call_sort: 'desc',
                call_order: 'created_at',
                open_trigger_sequence_modal: false,
                open_add_segment_modal: false,
                open_remove_segment_modal: false,
                open_add_attribute_modal: false,
                open_remove_attribute_modal: false,
                open_remove_recording_modal: false,
            }
        },
        methods: {
            fetch() {
                this.$http.get(`/api/v1/people/${this.id}`).then(this.refresh);
            },
            refresh({data}) {
                this.person = data.data;
                window.scrollTo(0,0);
            },
            fetchSegments() {
                this.$http.get(`/api/v1/segments?per_page=-1&search_types=static`).then(this.refreshSegments);
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                this.$http.get(`/api/v1/attributes?per_page=-1&search_scopes=client`).then(this.refreshAttributes);
            },
            refreshAttributes({data}) {
                this.attributes = data.data;
            },
            fetchCalls(page) {
                this.$http.get(`/api/v1/people/${this.id}/calls?page=${page}&per_page=25&sort=${this.call_sort}&order=${this.call_order}`).then(this.refreshCalls);
            },
            refreshCalls({data}) {
                this.calls = data.data;
                this.call_pages = data.meta.pages;
                window.scrollTo(0,0);
            },
            addToSegmentDialog() {
                this.add_segment_id = null
                this.segment_errors = []

                this.open_add_segment_modal = true;
            },
            addSegment() {
                this.$http.post(`/api/v1/people/${this.id}/segments/${this.add_segment_id}`).then(this.postSegmentAdd).catch(error => {
                    if (error.response.status === 422) {
                        this.segment_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while associating the segment',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postSegmentAdd() {
                this.open_add_segment_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The segment has been added',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },
            confirmRemoveFromSegment(segment_id) {
                this.remove_segment_id = segment_id;
                this.open_remove_segment_modal = true;
            },
            removeSegment() {
                this.$http.delete(`/api/v1/people/${this.id}/segments/${this.remove_segment_id}`).then(this.postSegmentRemove);
            },
            postSegmentRemove() {
                this.open_remove_segment_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The segment has been removed',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },
            addAttributeDialog() {
                this.add_attribute = null
                this.add_attribute_value = null
                this.attribute_errors = []

                this.open_add_attribute_modal = true;
            },
            addAttribute() {
                let attribute_id = (this.add_attribute ? this.add_attribute.id : null)

                this.$http.post(`/api/v1/people/${this.id}/attributes/${attribute_id}`, {value: this.add_attribute_value}).then(this.postAttributeAdd).catch(error => {
                    if (error.response.status === 422) {
                        this.attribute_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while associating the attribute',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postAttributeAdd() {
                this.open_add_attribute_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The attribute has been added',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },
            confirmRemoveAttribute(attribute_id) {
                this.remove_attribute_id = attribute_id;
                this.open_remove_attribute_modal = true;
            },
            removeAttribute() {
                this.$http.delete(`/api/v1/people/${this.id}/attributes/${this.remove_attribute_id}`).then(this.postAttributeRemove);
            },
            postAttributeRemove() {
                this.open_remove_attribute_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The attribute has been removed',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },
            confirmRemoveRecording(recording_id) {
                this.remove_recording_id = recording_id;

                this.open_remove_recording_modal = true;
            },
            removeRecording() {
                this.$http.delete(`/api/v1/people/${this.id}/recordings/${this.remove_recording_id}`).then(this.postRecordingRemove);
            },
            postRecordingRemove() {
                this.open_remove_recording_modal = true;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The recording has been removed',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },
            triggerSequenceDialog() {
                this.open_trigger_sequence_modal = true;
            },
            triggerSequence() {
                this.$http.post(`/api/v1/people/${this.id}/sequence/${this.trigger_sequence_id}`).then(this.postTriggerSequence).catch(error => {
                    if (error.response.status === 422) {
                        this.trigger_sequence_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while triggering the sequence',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postTriggerSequence() {
                this.open_trigger_sequence_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The sequence has been triggered',
                    duration: 5000,
                    speed: 1000
                });
            },
            updateCallSort(field) {
                if (field === this.call_order) {
                    this.call_sort = (this.call_sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.call_sort = 'asc';
                }

                this.call_order = field;

                this.fetchCalls(1);
            },
        }
    }
</script>
