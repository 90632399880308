<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Edit Report</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white shadow-md rounded">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Report Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.self">{{ errors.self[0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">

                            <!-- Row One -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="name">Name</label>
                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="name" type="text" v-model="report.name">
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                            </div>
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="status">Status</label>
                                <div class="relative">
                                <select class="form-select" id="status" name="status" v-model="report.status">
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                                </div>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                            </div>
                            </div>

                            <!-- Row Two -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="type">Type</label>
                                <div class="relative">
                                <select class="form-select" id="type" name="type" v-model="report.type">
                                    <option value="static">Static</option>
                                    <option value="dynamic">Dynamic</option>
                                </select>
                                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                                </div>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white" v-if="report.type === 'dynamic'">
                    <h2 class="p-4">Dynamic Report</h2>
                    <div class="bg-white shadow-md rounded my-6 p-4">
                        <div class="w-full">

                            <!-- Row 1 -->
                            <div class="border-solid border-2 border-gray-600 bg-gray-300 mb-4" v-for="(item, index) in report.dynamic_body" v-bind:key="index">
                                <div class="flex flex-wrap -mx-3 mb-6" v-for="(row, row_index) in item.items" v-bind:key="row_index">
                                    <div class="w-full md:w-2/7 px-3 mb-6 md:mb-0">
                                        <div class="relative">
                                            <select class="form-select" v-model="row.attribute" v-on:change="updateAttribute(index, row_index, $event)">
                                                <option value="" disabled>- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute">{{ attribute.name }}</option>
                                            </select>
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-2/7 px-3 mb-6 md:mb-0">
                                        <div class="relative" v-if="row.attribute">
                                            <select class="form-select" v-model="row.operator">
                                                <option value="=">=</option>
                                                <option value="!=">!=</option>
                                                <option value="<">&lt;</option>
                                                <option value="<=">&lt;=</option>
                                                <option value=">">&gt;</option>
                                                <option value=">=">&gt;=</option>
                                            </select>
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-2/7 px-3 mb-6 md:mb-0">
                                        <input class="form-text" v-model="row.value" v-if="row.attribute && row.attribute.values_type === 'open'">
                                        <div class="relative" v-if="row.attribute && row.attribute.values_type === 'predefined'">
                                            <select class="form-select" v-model="row.value">
                                                <option value="" disabled>- Select Value -</option>
                                                <option v-for="(value, value_index) in row.attribute.valid_values" v-bind:key="value_index" :value="value">{{ value }}</option>
                                            </select>
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/7 cursor-pointer h-full pt-8 pl-6">
                                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addRow(index)"><i class="fas fa-plus-circle"></i> OR</button>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-wrap mx-3 mb-6">
                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addBlock()"><i class="fas fa-plus-circle"></i> AND</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap m-5 px-3 pt-3">
                    <button class="btn btn-primary" v-on:click="save">
                        Update Report
                    </button>

                    <button class="btn btn-secondary" v-on:click="cancel">
                        Cancel
                    </button>
                </div>
            </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Number Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Reports provide a way to aggregate and collect data as CSV exports.</p>
                            <p class="text-xs italic pb-2"><strong>Name</strong> - Provide a descriptive, unique name for the report to help identify it for future downloads.  Example: Contact Congress Call Summary November 2020.</p>
                            <p class="text-xs italic pb-2"><strong>Type</strong> - Type of report to be created.  Call Summary provides call status summary information for the given numbers, over the specified date range.  Call Rep
                            Summary provides rep summary totals for the given numbers, over the specified date range (only applicable to numbers that use sequences with the Rep Lookup sequence block).</p>
                            <p class="text-xs italic pb-2"><strong>Number</strong> - If available, the numbers to use when compiling the report information.</p>
                            <p class="text-xs italic pb-2"><strong>Date Range</strong> - If available, the start and end date to use when compiling the report information.</p>
                            <p class="text-xs italic pb-2"><strong>Group By</strong> - If available, the summary aggregate to use.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'EditReport',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetchReport();
            this.fetchNumbers();
            this.fetchAttributes();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                numbers: [],
                attributes: [],
                report: {
                  id: '',
                  name: '',
                  number_id: null,
                  status: '',
                  type: 'static',
                  dynmaic_body: []
                },
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            fetchReport(id) {
                axios.get(this.reportUrl(id)).then(this.refreshReport);
            },
            reportUrl() {
                return `/api/v1/reports/${this.id}`;
            },
            refreshReport({data}) {
                this.report = data.data;

                if (this.report.type === 'dynamic') {
                    for (let block_index in this.report.dynamic_body) {
                        for (let row_index in this.report.dynamic_body[block_index].items) {
                            for (let attribute_index in this.attributes) {
                                if (this.attributes[attribute_index].id === this.report.dynamic_body[block_index].items[row_index].attribute_id) {
                                    this.report.dynamic_body[block_index].items[row_index].attribute = this.attributes[attribute_index];
                                    break;
                                }
                            }
                        }
                    }
                }

                console.dir(this.report.dynamic_body);
            },
            fetchNumbers() {
                axios.get(`/api/v1/numbers?per_page=-1`).then(this.refreshNumbers);
            },
            refreshNumbers({data}) {
                this.numbers = data.data;
            },
            fetchAttributes() {
                axios.get(this.attributesUrl()).then(this.refreshAttributes);
            },
            attributesUrl() {
                return `/api/v1/attributes?scope=client`;
            },
            refreshAttributes({
                data
            }) {
                this.attributes = data.data;
            },
            save() {
              axios.put('/api/v1/reports/'+this.id, this.report).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The report has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_reports'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while updating the report',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addBlock() {
                this.report.dynamic_body.push({
                    operator: 'OR',
                    items: [{
                        attribute: null,
                        attribute_id: null,
                        operator: '=',
                        value: null
                    }]
                })
            },
            addRow(block_index) {
                this.report.dynamic_body[block_index].items.push({
                    attribute: null,
                    attribute_id: null,
                    operator: '=',
                    value: null
                })
            },
            updateAttribute(block_index, row_index, event) {
                let id = event.target.value;

                for (let index in this.attributes) {
                    if (this.attributes[index].id === id) {
                        this.report.dynamic_body[block_index].items[row_index].attribute = this.attributes[index]
                        break
                    }
                }
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
